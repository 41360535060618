import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter'
import Head from 'next/head'
import { SnackbarProvider } from 'notistack'
import PropTypes from 'prop-types'
import React from 'react'
import Button from '@mui/material/Button'
import { LicenseInfo as NewLicenseInfo } from '@mui/x-license-pro'
import theme from '../src/theme'
import generatedIntrospection from '../gql/generated'
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import AuthChecker from '../components/auth-checker'
import Footer from '../components/footer'
import ErrorBoundary from '../components/error-boundary'
import { IdleTimerProvider } from 'react-idle-timer'
import SessionIdleDialog from '../src/session-idle'
import './app.css'

NewLicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_GRID_LICENSE)

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_API_NEXT_URL,
})

function getQueryStringToken() {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })
  return params.token
}

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  const qsToken = getQueryStringToken()

  return {
    headers: {
      ...headers,
      authorization: token || qsToken,
    },
  }
})

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
  }),
})

export default function MyApp(props) {
  const { Component, pageProps } = props

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  const notistackRef = React.createRef()
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key)
  }

  const [idle, setIdle] = React.useState(false)

  const onIdlePrompt = () => {
    if (localStorage && localStorage.getItem('token')) {
      setIdle(true)
    }
  }

  const onIdle = () => {
    if (localStorage && localStorage.getItem('token')) {
      setIdle(false)
      const token = localStorage.getItem('idtoken')
      localStorage.clear()
      // https://www.apollographql.com/docs/react/caching/advanced-topics/#resetting-the-cache
      apolloClient.clearStore()
      window.location.href =
        `https://${process.env.NEXT_PUBLIC_OKTA_DOMAIN}/v1/logout?id_token_hint=` +
        token +
        `&post_logout_redirect_uri=${process.env.NEXT_PUBLIC_OKTA_LOGOUTREDIR}`
    }
  }

  const onActive = () => {
    setIdle(false)
  }

  return (
    <React.Fragment>
      <AppCacheProvider {...props}>
        <Head>
          <title>nPOS</title>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width"
          />
        </Head>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={apolloClient}>
            <AuthChecker>
              <SnackbarProvider persist={true} maxSnack={20}
                ref={notistackRef}
                action={(key) => (
                  <Button onClick={onClickDismiss(key)} variant="contained">
                    Dismiss
                  </Button>
                )}
              >
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <ErrorBoundary>
                  <IdleTimerProvider
                    timeout={1000 * 60 * 27}
                    promptBeforeIdle={1000 * 60 * 3}
                    crossTab={true}
                    onPrompt={onIdlePrompt}
                    onIdle={onIdle}
                    onActive={onActive}
                  >
                    <Component {...pageProps} />
                    <SessionIdleDialog open={idle} />
                  </IdleTimerProvider>
                </ErrorBoundary>
                <Footer />
              </SnackbarProvider>
            </AuthChecker>
          </ApolloProvider>
        </ThemeProvider>
      </AppCacheProvider>
    </React.Fragment>
  )
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
}
