export const CREATE_MODULE = '/admin/modules/create'
export const ADD_MODULE_TO_ACCOUNT = '/admin/modules/add-to-account'
export const ACCOUNT_MODULE_LIST = '/admin/modules'
export function viewAccountModule(accountId: number) {
  return `/admin/modules/account/${accountId}`
}
export const POS = '/pos'
export const INVENTORY = '/invMgmt'
export const ME = '/me'
export const ECM = '/ecm'
export const LOGIN = '/'
export const HOME = '/home'
export const REPORTING = '/reports'

export function viewSupportOrder(accountId: number, orderId: string) {
  return `/support/account/${accountId}/order/${orderId}`
}
export function viewSupportOrderList(accountId: number) {
  return `/support/account/${accountId}`
}
export function viewSupportOrderListV2(accountId: number) {
  return `/support/v2/${accountId}`
}
