import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { LOGIN } from '../src/routes'

export default function AuthChecker({ children }) {
  const router = useRouter()

  useEffect(() => {
    if (router.pathname === LOGIN) return
    // todo: check validity of token and redirect if expired
  }, [router])

  return children
}
