import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import React, { useEffect, useState } from 'react'
import { useIdleTimerContext } from 'react-idle-timer'

export default function SessionIdleDialog({ open }) {
  const idleTimer = useIdleTimerContext()
  const [remainingTime, setRemainingTime] = useState(
    idleTimer.getRemainingTime()
  )
  const onCancel = () => {
    idleTimer.activate()
  }

  // show time remaining
  useEffect(() => {
    setInterval(() => {
      setRemainingTime(Math.floor(idleTimer.getRemainingTime() / 1000))
    }, 1000)
  }, [idleTimer])

  return (
    <Dialog open={open} aria-labelledby="form-dialog-tender" onClose={onCancel}>
      <DialogTitle>Session is idle</DialogTitle>
      <DialogContent>
        You will be logged out in {remainingTime} seconds.
      </DialogContent>
      <DialogActions>
        <Button
          id="idleCancelBtn"
          name="idleCancelBtn"
          onClick={onCancel}
          color="primary"
        >
          {' '}
          I&apos;m still here{' '}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
