import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Byte: { input: any; output: any }
  DateTime: { input: any; output: any }
  Decimal: { input: any; output: any }
  Long: { input: any; output: any }
  UUID: { input: any; output: any }
}

export type Account = {
  __typename?: 'Account'
  accountModules?: Maybe<Array<Maybe<AccountModule>>>
  active?: Maybe<Scalars['Boolean']['output']>
  auditUser?: Maybe<Scalars['String']['output']>
  billable?: Maybe<Scalars['Boolean']['output']>
  billingAccountId?: Maybe<Scalars['Int']['output']>
  company?: Maybe<Scalars['String']['output']>
  dateCreated?: Maybe<Scalars['DateTime']['output']>
  dateModified: Scalars['DateTime']['output']
  dbtimeStamp?: Maybe<Array<Scalars['Byte']['output']>>
  deleted?: Maybe<Scalars['Boolean']['output']>
  demo: Scalars['Boolean']['output']
  ediIsa05?: Maybe<Scalars['String']['output']>
  ediIsa06?: Maybe<Scalars['String']['output']>
  guid: Scalars['UUID']['output']
  id: Scalars['Long']['output']
  imagePath?: Maybe<Scalars['String']['output']>
  ncBillingContactId?: Maybe<Scalars['Int']['output']>
  parentAccountId?: Maybe<Scalars['Int']['output']>
  partnerAccountId?: Maybe<Scalars['Int']['output']>
}

export type AccountIdInput = {
  accountId: Scalars['Long']['input']
}

export type AccountInput = {
  accountModules?: InputMaybe<Array<InputMaybe<AccountModuleInput>>>
  active?: InputMaybe<Scalars['Boolean']['input']>
  auditUser?: InputMaybe<Scalars['String']['input']>
  billable?: InputMaybe<Scalars['Boolean']['input']>
  billingAccountId?: InputMaybe<Scalars['Int']['input']>
  company?: InputMaybe<Scalars['String']['input']>
  dateCreated?: InputMaybe<Scalars['DateTime']['input']>
  dateModified: Scalars['DateTime']['input']
  dbtimeStamp?: InputMaybe<Array<Scalars['Byte']['input']>>
  deleted?: InputMaybe<Scalars['Boolean']['input']>
  demo: Scalars['Boolean']['input']
  ediIsa05?: InputMaybe<Scalars['String']['input']>
  ediIsa06?: InputMaybe<Scalars['String']['input']>
  guid: Scalars['UUID']['input']
  id: Scalars['Long']['input']
  imagePath?: InputMaybe<Scalars['String']['input']>
  ncBillingContactId?: InputMaybe<Scalars['Int']['input']>
  parentAccountId?: InputMaybe<Scalars['Int']['input']>
  partnerAccountId?: InputMaybe<Scalars['Int']['input']>
}

export type AccountModule = {
  __typename?: 'AccountModule'
  account?: Maybe<Account>
  accountId: Scalars['Long']['output']
  id: Scalars['Int']['output']
  module?: Maybe<Module>
  moduleId: Scalars['Int']['output']
}

export type AccountModuleDto = {
  __typename?: 'AccountModuleDto'
  accountId: Scalars['Long']['output']
  accountName?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  modules?: Maybe<Array<Maybe<Module>>>
}

export type AccountModuleInput = {
  account?: InputMaybe<AccountInput>
  accountId: Scalars['Long']['input']
  id: Scalars['Int']['input']
  module?: InputMaybe<ModuleInput>
  moduleId: Scalars['Int']['input']
}

export type AccountSearchDto = {
  __typename?: 'AccountSearchDto'
  id: Scalars['Long']['output']
  name?: Maybe<Scalars['String']['output']>
}

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
}

export type ApprovalStepInformation = {
  __typename?: 'ApprovalStepInformation'
  completeCount: Scalars['Int']['output']
  currentStepName?: Maybe<Scalars['String']['output']>
  percentComplete?: Maybe<Scalars['Float']['output']>
  stepTotal: Scalars['Int']['output']
}

export enum ApproveRequestDecision {
  Approve = 'APPROVE',
  Deny = 'DENY',
}

export type ApproveRequestInput = {
  decision: ApproveRequestDecision
  notes?: InputMaybe<Scalars['String']['input']>
  orderId: Scalars['UUID']['input']
  userId: Scalars['Int']['input']
}

export type AssociateUsersToLocationsInput = {
  locationIds?: InputMaybe<Array<Scalars['Long']['input']>>
  userIds?: InputMaybe<Array<Scalars['Long']['input']>>
}

export type Attribute1 = {
  __typename?: 'Attribute1'
  accountElementId?: Maybe<Scalars['UUID']['output']>
  auditUser?: Maybe<Scalars['String']['output']>
  channelId?: Maybe<Scalars['Int']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dbtimeStamp?: Maybe<Array<Scalars['Byte']['output']>>
  deleted?: Maybe<Scalars['Boolean']['output']>
  groupName?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  item?: Maybe<Item>
  itemId?: Maybe<Scalars['UUID']['output']>
  locationId?: Maybe<Scalars['Long']['output']>
  name?: Maybe<Scalars['String']['output']>
  private?: Maybe<Scalars['Boolean']['output']>
  regionId?: Maybe<Scalars['Long']['output']>
  sequence?: Maybe<Scalars['Int']['output']>
  templateAttributeWizardId?: Maybe<Scalars['Long']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type Attribute2 = {
  __typename?: 'Attribute2'
  auditUser?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dbtimeStamp?: Maybe<Array<Scalars['Byte']['output']>>
  deleted?: Maybe<Scalars['Boolean']['output']>
  groupName?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  name?: Maybe<Scalars['String']['output']>
  order?: Maybe<Order>
  orderId?: Maybe<Scalars['UUID']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type Attribute2FilterInput = {
  and?: InputMaybe<Array<Attribute2FilterInput>>
  auditUser?: InputMaybe<StringOperationFilterInput>
  dateCreated?: InputMaybe<ComparableDateTimeOperationFilterInput>
  dateModified?: InputMaybe<ComparableDateTimeOperationFilterInput>
  dbtimeStamp?: InputMaybe<ListComparableByteOperationFilterInput>
  deleted?: InputMaybe<BooleanOperationFilterInput>
  groupName?: InputMaybe<StringOperationFilterInput>
  id?: InputMaybe<ComparableGuidOperationFilterInput>
  name?: InputMaybe<StringOperationFilterInput>
  or?: InputMaybe<Array<Attribute2FilterInput>>
  order?: InputMaybe<OrderFilterInput>
  orderId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>
  value?: InputMaybe<StringOperationFilterInput>
}

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>
  neq?: InputMaybe<Scalars['Boolean']['input']>
}

export type ComparableByteOperationFilterInput = {
  eq?: InputMaybe<Scalars['Byte']['input']>
  gt?: InputMaybe<Scalars['Byte']['input']>
  gte?: InputMaybe<Scalars['Byte']['input']>
  in?: InputMaybe<Array<Scalars['Byte']['input']>>
  lt?: InputMaybe<Scalars['Byte']['input']>
  lte?: InputMaybe<Scalars['Byte']['input']>
  neq?: InputMaybe<Scalars['Byte']['input']>
  ngt?: InputMaybe<Scalars['Byte']['input']>
  ngte?: InputMaybe<Scalars['Byte']['input']>
  nin?: InputMaybe<Array<Scalars['Byte']['input']>>
  nlt?: InputMaybe<Scalars['Byte']['input']>
  nlte?: InputMaybe<Scalars['Byte']['input']>
}

export type ComparableDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  gte?: InputMaybe<Scalars['DateTime']['input']>
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
  neq?: InputMaybe<Scalars['DateTime']['input']>
  ngt?: InputMaybe<Scalars['DateTime']['input']>
  ngte?: InputMaybe<Scalars['DateTime']['input']>
  nin?: InputMaybe<Array<Scalars['DateTime']['input']>>
  nlt?: InputMaybe<Scalars['DateTime']['input']>
  nlte?: InputMaybe<Scalars['DateTime']['input']>
}

export type ComparableGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']['input']>
  gt?: InputMaybe<Scalars['UUID']['input']>
  gte?: InputMaybe<Scalars['UUID']['input']>
  in?: InputMaybe<Array<Scalars['UUID']['input']>>
  lt?: InputMaybe<Scalars['UUID']['input']>
  lte?: InputMaybe<Scalars['UUID']['input']>
  neq?: InputMaybe<Scalars['UUID']['input']>
  ngt?: InputMaybe<Scalars['UUID']['input']>
  ngte?: InputMaybe<Scalars['UUID']['input']>
  nin?: InputMaybe<Array<Scalars['UUID']['input']>>
  nlt?: InputMaybe<Scalars['UUID']['input']>
  nlte?: InputMaybe<Scalars['UUID']['input']>
}

export type ComparableInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>
  gt?: InputMaybe<Scalars['Int']['input']>
  gte?: InputMaybe<Scalars['Int']['input']>
  in?: InputMaybe<Array<Scalars['Int']['input']>>
  lt?: InputMaybe<Scalars['Int']['input']>
  lte?: InputMaybe<Scalars['Int']['input']>
  neq?: InputMaybe<Scalars['Int']['input']>
  ngt?: InputMaybe<Scalars['Int']['input']>
  ngte?: InputMaybe<Scalars['Int']['input']>
  nin?: InputMaybe<Array<Scalars['Int']['input']>>
  nlt?: InputMaybe<Scalars['Int']['input']>
  nlte?: InputMaybe<Scalars['Int']['input']>
}

export type ComparableInt64OperationFilterInput = {
  eq?: InputMaybe<Scalars['Long']['input']>
  gt?: InputMaybe<Scalars['Long']['input']>
  gte?: InputMaybe<Scalars['Long']['input']>
  in?: InputMaybe<Array<Scalars['Long']['input']>>
  lt?: InputMaybe<Scalars['Long']['input']>
  lte?: InputMaybe<Scalars['Long']['input']>
  neq?: InputMaybe<Scalars['Long']['input']>
  ngt?: InputMaybe<Scalars['Long']['input']>
  ngte?: InputMaybe<Scalars['Long']['input']>
  nin?: InputMaybe<Array<Scalars['Long']['input']>>
  nlt?: InputMaybe<Scalars['Long']['input']>
  nlte?: InputMaybe<Scalars['Long']['input']>
}

export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  gte?: InputMaybe<Scalars['DateTime']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
  neq?: InputMaybe<Scalars['DateTime']['input']>
  ngt?: InputMaybe<Scalars['DateTime']['input']>
  ngte?: InputMaybe<Scalars['DateTime']['input']>
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  nlt?: InputMaybe<Scalars['DateTime']['input']>
  nlte?: InputMaybe<Scalars['DateTime']['input']>
}

export type ComparableNullableOfDecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']['input']>
  gt?: InputMaybe<Scalars['Decimal']['input']>
  gte?: InputMaybe<Scalars['Decimal']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>
  lt?: InputMaybe<Scalars['Decimal']['input']>
  lte?: InputMaybe<Scalars['Decimal']['input']>
  neq?: InputMaybe<Scalars['Decimal']['input']>
  ngt?: InputMaybe<Scalars['Decimal']['input']>
  ngte?: InputMaybe<Scalars['Decimal']['input']>
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>
  nlt?: InputMaybe<Scalars['Decimal']['input']>
  nlte?: InputMaybe<Scalars['Decimal']['input']>
}

export type ComparableNullableOfGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']['input']>
  gt?: InputMaybe<Scalars['UUID']['input']>
  gte?: InputMaybe<Scalars['UUID']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>
  lt?: InputMaybe<Scalars['UUID']['input']>
  lte?: InputMaybe<Scalars['UUID']['input']>
  neq?: InputMaybe<Scalars['UUID']['input']>
  ngt?: InputMaybe<Scalars['UUID']['input']>
  ngte?: InputMaybe<Scalars['UUID']['input']>
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>
  nlt?: InputMaybe<Scalars['UUID']['input']>
  nlte?: InputMaybe<Scalars['UUID']['input']>
}

export type ComparableNullableOfInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>
  gt?: InputMaybe<Scalars['Int']['input']>
  gte?: InputMaybe<Scalars['Int']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  lt?: InputMaybe<Scalars['Int']['input']>
  lte?: InputMaybe<Scalars['Int']['input']>
  neq?: InputMaybe<Scalars['Int']['input']>
  ngt?: InputMaybe<Scalars['Int']['input']>
  ngte?: InputMaybe<Scalars['Int']['input']>
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  nlt?: InputMaybe<Scalars['Int']['input']>
  nlte?: InputMaybe<Scalars['Int']['input']>
}

export type ComparableNullableOfInt64OperationFilterInput = {
  eq?: InputMaybe<Scalars['Long']['input']>
  gt?: InputMaybe<Scalars['Long']['input']>
  gte?: InputMaybe<Scalars['Long']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>
  lt?: InputMaybe<Scalars['Long']['input']>
  lte?: InputMaybe<Scalars['Long']['input']>
  neq?: InputMaybe<Scalars['Long']['input']>
  ngt?: InputMaybe<Scalars['Long']['input']>
  ngte?: InputMaybe<Scalars['Long']['input']>
  nin?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>
  nlt?: InputMaybe<Scalars['Long']['input']>
  nlte?: InputMaybe<Scalars['Long']['input']>
}

export type CreateAccountModuleInput = {
  accountId: Scalars['Long']['input']
  moduleIds?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type CreateModuleInput = {
  code?: InputMaybe<Scalars['String']['input']>
}

export enum CreditCardAuthType {
  GcpcAuth = 'GCPC_AUTH',
  None = 'NONE',
  TestAuth = 'TEST_AUTH',
}

export type CreditCardDto = {
  __typename?: 'CreditCardDto'
  id: Scalars['UUID']['output']
  name?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type CreditCardDtoInput = {
  id: Scalars['UUID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

export type Customer = {
  __typename?: 'Customer'
  accountBalance?: Maybe<Scalars['Decimal']['output']>
  accountId: Scalars['Long']['output']
  accountNumber?: Maybe<Scalars['String']['output']>
  accountOpened?: Maybe<Scalars['DateTime']['output']>
  assessFinanceCharge?: Maybe<Scalars['Boolean']['output']>
  auditUser?: Maybe<Scalars['String']['output']>
  billingId?: Maybe<Scalars['UUID']['output']>
  company?: Maybe<Scalars['String']['output']>
  creditLimit?: Maybe<Scalars['Decimal']['output']>
  currentDiscount?: Maybe<Scalars['Decimal']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dbtimeStamp?: Maybe<Array<Scalars['Byte']['output']>>
  defaultShipToId?: Maybe<Scalars['UUID']['output']>
  deleted?: Maybe<Scalars['Boolean']['output']>
  ediIsa07?: Maybe<Scalars['String']['output']>
  ediIsa08?: Maybe<Scalars['String']['output']>
  ediIsa12?: Maybe<Scalars['String']['output']>
  emailAddress?: Maybe<Scalars['String']['output']>
  employee?: Maybe<Scalars['Boolean']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  globalCustomer?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['UUID']['output']
  inactive?: Maybe<Scalars['Boolean']['output']>
  lastClosingBalance?: Maybe<Scalars['Decimal']['output']>
  lastClosingDate?: Maybe<Scalars['DateTime']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  lastStartingDate?: Maybe<Scalars['DateTime']['output']>
  lastVisit?: Maybe<Scalars['DateTime']['output']>
  limitPurchase?: Maybe<Scalars['Boolean']['output']>
  nCustomerId?: Maybe<Scalars['Long']['output']>
  notes?: Maybe<Scalars['String']['output']>
  parentId?: Maybe<Scalars['UUID']['output']>
  priceLevel?: Maybe<Scalars['Int']['output']>
  sourceChannelId?: Maybe<Scalars['Int']['output']>
  taxExempt?: Maybe<Scalars['Boolean']['output']>
  taxNumber?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  totalSales?: Maybe<Scalars['Decimal']['output']>
  totalSavings?: Maybe<Scalars['Decimal']['output']>
  totalVisits?: Maybe<Scalars['Int']['output']>
}

export type CustomerSearchDto = {
  __typename?: 'CustomerSearchDto'
  accountNumber?: Maybe<Scalars['String']['output']>
  agencyCode?: Maybe<Scalars['String']['output']>
  altEmail?: Maybe<Scalars['String']['output']>
  altEmail2?: Maybe<Scalars['String']['output']>
  altEmail3?: Maybe<Scalars['String']['output']>
  bOAC?: Maybe<Scalars['String']['output']>
  cCCustomer?: Maybe<Scalars['String']['output']>
  company?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  fundCode?: Maybe<Scalars['String']['output']>
  globalCustomer?: Maybe<Scalars['Boolean']['output']>
  id?: Maybe<Scalars['UUID']['output']>
  inactive?: Maybe<Scalars['Boolean']['output']>
  jon?: Maybe<Scalars['String']['output']>
  parentId?: Maybe<Scalars['UUID']['output']>
  telephone?: Maybe<Scalars['String']['output']>
}

export type CustomerSearchDtoInput = {
  accountNumber?: InputMaybe<Scalars['String']['input']>
  agencyCode?: InputMaybe<Scalars['String']['input']>
  altEmail?: InputMaybe<Scalars['String']['input']>
  altEmail2?: InputMaybe<Scalars['String']['input']>
  altEmail3?: InputMaybe<Scalars['String']['input']>
  bOAC?: InputMaybe<Scalars['String']['input']>
  cCCustomer?: InputMaybe<Scalars['String']['input']>
  company?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  fundCode?: InputMaybe<Scalars['String']['input']>
  globalCustomer?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  inactive?: InputMaybe<Scalars['Boolean']['input']>
  jon?: InputMaybe<Scalars['String']['input']>
  parentId?: InputMaybe<Scalars['UUID']['input']>
  telephone?: InputMaybe<Scalars['String']['input']>
}

export type DisassociateUsersToLocationsInput = {
  locationIds?: InputMaybe<Array<Scalars['Long']['input']>>
  userIds?: InputMaybe<Array<Scalars['Long']['input']>>
}

export type DodaacBeaBesa = {
  __typename?: 'DodaacBeaBesa'
  id: Scalars['UUID']['output']
  value?: Maybe<Scalars['String']['output']>
}

export type Entry = {
  __typename?: 'Entry'
  auditUser?: Maybe<Scalars['String']['output']>
  comment?: Maybe<Scalars['String']['output']>
  cost?: Maybe<Scalars['Decimal']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dbtimeStamp?: Maybe<Array<Scalars['Byte']['output']>>
  deleted?: Maybe<Scalars['Boolean']['output']>
  description?: Maybe<Scalars['String']['output']>
  discountReasonCodeId?: Maybe<Scalars['UUID']['output']>
  dropShipped?: Maybe<Scalars['Boolean']['output']>
  dropShippedOrderEntryId?: Maybe<Scalars['UUID']['output']>
  dropShippedOrderId?: Maybe<Scalars['UUID']['output']>
  entryAttributes?: Maybe<Array<Maybe<EntryAttribute>>>
  friendlyId: Scalars['Long']['output']
  fullPrice?: Maybe<Scalars['Decimal']['output']>
  holdTill?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['UUID']['output']
  item?: Maybe<Item>
  itemId?: Maybe<Scalars['UUID']['output']>
  itemSerialNumberId?: Maybe<Scalars['UUID']['output']>
  lastUpdated?: Maybe<Scalars['DateTime']['output']>
  lineReference?: Maybe<Scalars['String']['output']>
  orderId?: Maybe<Scalars['UUID']['output']>
  orderSku?: Maybe<Scalars['String']['output']>
  originatingOrderEntryId?: Maybe<Scalars['UUID']['output']>
  poid?: Maybe<Scalars['UUID']['output']>
  quantityInTransit?: Maybe<Scalars['Decimal']['output']>
  quantityOnOrder?: Maybe<Scalars['Decimal']['output']>
  quantityRtd?: Maybe<Scalars['Decimal']['output']>
  returnReasonCodeId?: Maybe<Scalars['UUID']['output']>
  salePrice?: Maybe<Scalars['Decimal']['output']>
  serialNumber?: Maybe<Scalars['String']['output']>
  shipVia?: Maybe<Scalars['String']['output']>
  shippingAddressId?: Maybe<Scalars['UUID']['output']>
  sku?: Maybe<Scalars['String']['output']>
  status: Scalars['Int']['output']
  tax?: Maybe<Scalars['Decimal']['output']>
  taxChangeReasonCodeId?: Maybe<Scalars['UUID']['output']>
  taxable?: Maybe<Scalars['Boolean']['output']>
}

export type EntryAttribute = {
  __typename?: 'EntryAttribute'
  auditUser?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dbtimeStamp?: Maybe<Array<Scalars['Byte']['output']>>
  deleted?: Maybe<Scalars['Boolean']['output']>
  groupName?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  name?: Maybe<Scalars['String']['output']>
  orderEntry?: Maybe<Entry>
  orderEntryId?: Maybe<Scalars['UUID']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type Event = {
  __typename?: 'Event'
  data?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  entity?: Maybe<Order>
  entityId: Scalars['UUID']['output']
  id: Scalars['UUID']['output']
  name?: Maybe<Scalars['String']['output']>
  version: Scalars['Int']['output']
}

export type EventFilterInput = {
  and?: InputMaybe<Array<EventFilterInput>>
  data?: InputMaybe<StringOperationFilterInput>
  dateCreated?: InputMaybe<ComparableDateTimeOperationFilterInput>
  entity?: InputMaybe<OrderFilterInput>
  entityId?: InputMaybe<ComparableGuidOperationFilterInput>
  id?: InputMaybe<ComparableGuidOperationFilterInput>
  name?: InputMaybe<StringOperationFilterInput>
  or?: InputMaybe<Array<EventFilterInput>>
  version?: InputMaybe<ComparableInt32OperationFilterInput>
}

export type EventIdk = {
  __typename?: 'EventIdk'
  data?: Maybe<Scalars['String']['output']>
  date?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  name?: Maybe<Scalars['String']['output']>
}

export type GetPrintOrdersInput = {
  orderIds?: InputMaybe<Array<Scalars['UUID']['input']>>
}

export type HistoryIdk = {
  __typename?: 'HistoryIdk'
  date?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  message?: Maybe<Scalars['String']['output']>
}

export type InventoryModuleConfiguration = {
  __typename?: 'InventoryModuleConfiguration'
  accountId: Scalars['Long']['output']
  id: Scalars['Int']['output']
  module?: Maybe<Module>
  reasonCodes?: Maybe<Array<Maybe<ReasonCodeDto>>>
}

export type Item = {
  __typename?: 'Item'
  accountId: Scalars['Int']['output']
  attribute1s?: Maybe<Array<Maybe<Attribute1>>>
  auditUser?: Maybe<Scalars['String']['output']>
  barCodeTypeId?: Maybe<Scalars['Int']['output']>
  blockSalesReasonCode?: Maybe<Scalars['String']['output']>
  brandId?: Maybe<Scalars['UUID']['output']>
  consignment?: Maybe<Scalars['Boolean']['output']>
  content?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dbtimeStamp?: Maybe<Array<Scalars['Byte']['output']>>
  delayedCapture?: Maybe<Scalars['Boolean']['output']>
  deleted?: Maybe<Scalars['Boolean']['output']>
  description?: Maybe<Scalars['String']['output']>
  dimensionDepth?: Maybe<Scalars['Decimal']['output']>
  dimensionHeight?: Maybe<Scalars['Decimal']['output']>
  dimensionUom?: Maybe<Scalars['String']['output']>
  dimensionWidth?: Maybe<Scalars['Decimal']['output']>
  doNotOrder?: Maybe<Scalars['Boolean']['output']>
  dropShipType?: Maybe<Scalars['Int']['output']>
  entries?: Maybe<Array<Maybe<Entry>>>
  genderId?: Maybe<Scalars['Int']['output']>
  id: Scalars['UUID']['output']
  imageName?: Maybe<Scalars['String']['output']>
  inactive?: Maybe<Scalars['Boolean']['output']>
  isApack?: Maybe<Scalars['Boolean']['output']>
  itemNotDiscountable?: Maybe<Scalars['Boolean']['output']>
  itemParentId?: Maybe<Scalars['UUID']['output']>
  itemTypeId?: Maybe<Scalars['Int']['output']>
  lastUpdate?: Maybe<Scalars['DateTime']['output']>
  longDescription?: Maybe<Scalars['String']['output']>
  manufacturerId?: Maybe<Scalars['UUID']['output']>
  masterConnectionId?: Maybe<Scalars['Int']['output']>
  masterCost?: Maybe<Scalars['Decimal']['output']>
  masterPrice?: Maybe<Scalars['Decimal']['output']>
  msrpprice?: Maybe<Scalars['Decimal']['output']>
  name?: Maybe<Scalars['String']['output']>
  nmfccode?: Maybe<Scalars['String']['output']>
  notes?: Maybe<Scalars['String']['output']>
  packSize?: Maybe<Scalars['Int']['output']>
  parentQuantity?: Maybe<Scalars['Int']['output']>
  priceMustBeEntered?: Maybe<Scalars['Boolean']['output']>
  primarySupplierId?: Maybe<Scalars['UUID']['output']>
  quantityEntryNotAllowed?: Maybe<Scalars['Boolean']['output']>
  sku?: Maybe<Scalars['String']['output']>
  sourceChannelChannelId?: Maybe<Scalars['Long']['output']>
  sourceChannelId?: Maybe<Scalars['Long']['output']>
  sourceId?: Maybe<Scalars['Int']['output']>
  sourceLocationId?: Maybe<Scalars['Long']['output']>
  title?: Maybe<Scalars['String']['output']>
  unitOfMeasure?: Maybe<Scalars['String']['output']>
  weight?: Maybe<Scalars['Decimal']['output']>
  weightUom?: Maybe<Scalars['String']['output']>
}

export type ItemBySkuInput = {
  locationId: Scalars['Long']['input']
  sku?: InputMaybe<Scalars['String']['input']>
  transactionType: TransactionType
}

export type ItemDto = {
  __typename?: 'ItemDto'
  id: Scalars['UUID']['output']
  isHmms: Scalars['Boolean']['output']
  masterCost: Scalars['Decimal']['output']
  masterPrice: Scalars['Decimal']['output']
  sku?: Maybe<Scalars['String']['output']>
  vendorPrefix?: Maybe<Scalars['String']['output']>
}

export type ListComparableByteOperationFilterInput = {
  all?: InputMaybe<ComparableByteOperationFilterInput>
  any?: InputMaybe<Scalars['Boolean']['input']>
  none?: InputMaybe<ComparableByteOperationFilterInput>
  some?: InputMaybe<ComparableByteOperationFilterInput>
}

export type ListFilterInputTypeOfAttribute2FilterInput = {
  all?: InputMaybe<Attribute2FilterInput>
  any?: InputMaybe<Scalars['Boolean']['input']>
  none?: InputMaybe<Attribute2FilterInput>
  some?: InputMaybe<Attribute2FilterInput>
}

export type ListFilterInputTypeOfEventFilterInput = {
  all?: InputMaybe<EventFilterInput>
  any?: InputMaybe<Scalars['Boolean']['input']>
  none?: InputMaybe<EventFilterInput>
  some?: InputMaybe<EventFilterInput>
}

export type Location = {
  __typename?: 'Location'
  accountId: Scalars['Long']['output']
  accountingCustomerNumber?: Maybe<Scalars['String']['output']>
  active?: Maybe<Scalars['Boolean']['output']>
  address1?: Maybe<Scalars['String']['output']>
  address2?: Maybe<Scalars['String']['output']>
  auditUser?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dbtimeStamp?: Maybe<Array<Scalars['Byte']['output']>>
  deleted?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['Long']['output']
  lastUpdated?: Maybe<Scalars['DateTime']['output']>
  name?: Maybe<Scalars['String']['output']>
  regionId?: Maybe<Scalars['Long']['output']>
  retryCount?: Maybe<Scalars['Int']['output']>
  retryDelay?: Maybe<Scalars['Int']['output']>
  state?: Maybe<Scalars['String']['output']>
  storeCode?: Maybe<Scalars['String']['output']>
  zipCode?: Maybe<Scalars['String']['output']>
}

export type LocationDto = {
  __typename?: 'LocationDto'
  id: Scalars['Long']['output']
  name?: Maybe<Scalars['String']['output']>
  users?: Maybe<Array<Maybe<UserDto>>>
}

export type LocationHmmsUpdateInput = {
  accountId: Scalars['Long']['input']
  hmmsId: Scalars['Int']['input']
  hmmsLocationId?: InputMaybe<Scalars['String']['input']>
  locationId: Scalars['Long']['input']
}

export type LocationsModuleConfiguration = {
  __typename?: 'LocationsModuleConfiguration'
  id: Scalars['Int']['output']
  module?: Maybe<Module>
}

export type Me = {
  __typename?: 'Me'
  accountId: Scalars['Long']['output']
  id: Scalars['Long']['output']
  permissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

export type Module = {
  __typename?: 'Module'
  accountModules?: Maybe<Array<Maybe<AccountModule>>>
  code?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
}

export type ModuleConfiguration =
  | InventoryModuleConfiguration
  | LocationsModuleConfiguration
  | NoModuleConfiguration
  | PosModuleConfiguration
  | WorkflowModuleConfiguration

export type ModuleConfigurationsResponse = {
  __typename?: 'ModuleConfigurationsResponse'
  accountName?: Maybe<Scalars['String']['output']>
  configurations?: Maybe<Array<Maybe<ModuleConfiguration>>>
}

export type ModuleInput = {
  accountModules?: InputMaybe<Array<InputMaybe<AccountModuleInput>>>
  code?: InputMaybe<Scalars['String']['input']>
  id: Scalars['Int']['input']
}

export type Mutation = {
  __typename?: 'Mutation'
  associateUsersToLocations: Scalars['Boolean']['output']
  createAccountModule: Scalars['Boolean']['output']
  createGsaAttribute?: Maybe<ModuleConfigurationsResponse>
  createPosLocationDevice?: Maybe<ModuleConfigurationsResponse>
  createTemplateModule?: Maybe<Module>
  disassociateUsersToLocations: Scalars['Boolean']['output']
  locationHmmsDelete?: Maybe<Array<Maybe<PosModuleConfigurationLocation>>>
  locationHmmsUpdate?: Maybe<Array<Maybe<PosModuleConfigurationLocation>>>
  posRoutingDelete?: Maybe<PosModuleConfiguration>
  posRoutingUpdate?: Maybe<PosModuleConfiguration>
  posTestCreditCardCreate?: Maybe<CreditCardDto>
  posTestCreditCardDelete: Scalars['Boolean']['output']
  updateCashierId?: Maybe<UserDto>
  updateCustomer: Scalars['Boolean']['output']
  updateInventoryModuleConfiguration: Scalars['Boolean']['output']
  updatePosModuleConfiguration: Scalars['Boolean']['output']
  usersAddToDodaacBeaBesa: Scalars['Boolean']['output']
  usersRemoveFromDodaacBeaBesa: Scalars['Boolean']['output']
  workflowApproveOrDeny: Scalars['Boolean']['output']
  workflowDeleteOrderState: Scalars['Boolean']['output']
  workflowInvoke?: Maybe<WorkflowInvokeResponse>
  workflowModuleConfigurationUpdate: Scalars['Boolean']['output']
}

export type MutationAssociateUsersToLocationsArgs = {
  input?: InputMaybe<AssociateUsersToLocationsInput>
}

export type MutationCreateAccountModuleArgs = {
  input?: InputMaybe<CreateAccountModuleInput>
}

export type MutationCreateGsaAttributeArgs = {
  input?: InputMaybe<AccountIdInput>
}

export type MutationCreatePosLocationDeviceArgs = {
  input?: InputMaybe<AccountIdInput>
}

export type MutationCreateTemplateModuleArgs = {
  input?: InputMaybe<CreateModuleInput>
}

export type MutationDisassociateUsersToLocationsArgs = {
  input?: InputMaybe<DisassociateUsersToLocationsInput>
}

export type MutationLocationHmmsDeleteArgs = {
  input?: InputMaybe<LocationHmmsUpdateInput>
}

export type MutationLocationHmmsUpdateArgs = {
  input?: InputMaybe<LocationHmmsUpdateInput>
}

export type MutationPosRoutingDeleteArgs = {
  input?: InputMaybe<AccountIdInput>
}

export type MutationPosRoutingUpdateArgs = {
  input?: InputMaybe<PosRoutingUpdateInput>
}

export type MutationPosTestCreditCardCreateArgs = {
  input?: InputMaybe<PosTestCreditCardCreateInput>
}

export type MutationPosTestCreditCardDeleteArgs = {
  input?: InputMaybe<PosTestCreditCardDeleteInput>
}

export type MutationUpdateCashierIdArgs = {
  input?: InputMaybe<UpdateCashierIdInput>
}

export type MutationUpdateCustomerArgs = {
  input?: InputMaybe<CustomerSearchDtoInput>
}

export type MutationUpdateInventoryModuleConfigurationArgs = {
  input?: InputMaybe<UpdateInventoryModuleConfigurationInput>
}

export type MutationUpdatePosModuleConfigurationArgs = {
  input?: InputMaybe<UpdatePosModuleConfigurationInput>
}

export type MutationUsersAddToDodaacBeaBesaArgs = {
  input?: InputMaybe<UsersAddToDodaacBeaBesaInput>
}

export type MutationUsersRemoveFromDodaacBeaBesaArgs = {
  input?: InputMaybe<UsersRemoveFromDodaacBeaBesaInput>
}

export type MutationWorkflowApproveOrDenyArgs = {
  input?: InputMaybe<ApproveRequestInput>
}

export type MutationWorkflowDeleteOrderStateArgs = {
  orderId: Scalars['UUID']['input']
}

export type MutationWorkflowInvokeArgs = {
  input?: InputMaybe<WorkflowInvokeInput>
}

export type MutationWorkflowModuleConfigurationUpdateArgs = {
  input?: InputMaybe<WorkflowModuleConfigurationUpdateInput>
}

export type NoModuleConfiguration = {
  __typename?: 'NoModuleConfiguration'
  enabled: Scalars['Boolean']['output']
  id: Scalars['Int']['output']
  message?: Maybe<Scalars['String']['output']>
  module?: Maybe<Module>
}

export type Order = {
  __typename?: 'Order'
  accountChannelTransactionId?: Maybe<Scalars['Int']['output']>
  accountId: Scalars['Long']['output']
  anticipatedDeliveryDate?: Maybe<Scalars['DateTime']['output']>
  attribute2s?: Maybe<Array<Maybe<Attribute2>>>
  auditUser?: Maybe<Scalars['String']['output']>
  batchNumber?: Maybe<Scalars['String']['output']>
  billingAddressId?: Maybe<Scalars['UUID']['output']>
  cancelDate?: Maybe<Scalars['DateTime']['output']>
  confirmingTo?: Maybe<Scalars['String']['output']>
  customerId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dbtimeStamp?: Maybe<Array<Scalars['Byte']['output']>>
  deleted?: Maybe<Scalars['Boolean']['output']>
  deliveryNotes?: Maybe<Scalars['String']['output']>
  destinationChannelId?: Maybe<Scalars['Int']['output']>
  destinationLocationId?: Maybe<Scalars['Long']['output']>
  events?: Maybe<Array<Maybe<Event>>>
  expirationDate?: Maybe<Scalars['DateTime']['output']>
  fobpoint?: Maybe<Scalars['String']['output']>
  friendlyId: Scalars['Long']['output']
  id: Scalars['UUID']['output']
  inventoryLocation?: Maybe<Scalars['Int']['output']>
  isPlaced?: Maybe<Scalars['Boolean']['output']>
  locationId?: Maybe<Scalars['Long']['output']>
  notes?: Maybe<Scalars['String']['output']>
  orderDate: Scalars['DateTime']['output']
  orderTypeId: Scalars['Int']['output']
  originatingOrderId?: Maybe<Scalars['UUID']['output']>
  parentOrderId?: Maybe<Scalars['UUID']['output']>
  podateCreated?: Maybe<Scalars['DateTime']['output']>
  podatePlaced?: Maybe<Scalars['DateTime']['output']>
  porequisitioner?: Maybe<Scalars['String']['output']>
  poshipTo?: Maybe<Scalars['String']['output']>
  poto?: Maybe<Scalars['String']['output']>
  referenceNumber?: Maybe<Scalars['String']['output']>
  refundedDate?: Maybe<Scalars['DateTime']['output']>
  remoteReferenceNumber?: Maybe<Scalars['String']['output']>
  requestedDeliveryDate?: Maybe<Scalars['DateTime']['output']>
  requestedShippingMethodId?: Maybe<Scalars['UUID']['output']>
  requiredByDate?: Maybe<Scalars['DateTime']['output']>
  shipVia?: Maybe<Scalars['String']['output']>
  shipping?: Maybe<Scalars['Decimal']['output']>
  shippingAddressId?: Maybe<Scalars['UUID']['output']>
  sourceChannelId: Scalars['Int']['output']
  sourceLocationId?: Maybe<Scalars['Long']['output']>
  status: Scalars['Int']['output']
  subTotal?: Maybe<Scalars['Decimal']['output']>
  supplierId?: Maybe<Scalars['UUID']['output']>
  tax?: Maybe<Scalars['Decimal']['output']>
  terms?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  total?: Maybe<Scalars['Decimal']['output']>
  workFlowId?: Maybe<Scalars['Long']['output']>
}

export type OrderFilterInput = {
  accountChannelTransactionId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>
  accountId?: InputMaybe<ComparableInt64OperationFilterInput>
  and?: InputMaybe<Array<OrderFilterInput>>
  anticipatedDeliveryDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>
  attribute2s?: InputMaybe<ListFilterInputTypeOfAttribute2FilterInput>
  auditUser?: InputMaybe<StringOperationFilterInput>
  batchNumber?: InputMaybe<StringOperationFilterInput>
  billingAddressId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>
  cancelDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>
  confirmingTo?: InputMaybe<StringOperationFilterInput>
  customerId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>
  dateCreated?: InputMaybe<ComparableDateTimeOperationFilterInput>
  dateModified?: InputMaybe<ComparableDateTimeOperationFilterInput>
  dbtimeStamp?: InputMaybe<ListComparableByteOperationFilterInput>
  deleted?: InputMaybe<BooleanOperationFilterInput>
  deliveryNotes?: InputMaybe<StringOperationFilterInput>
  destinationChannelId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>
  destinationLocationId?: InputMaybe<ComparableNullableOfInt64OperationFilterInput>
  events?: InputMaybe<ListFilterInputTypeOfEventFilterInput>
  expirationDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>
  fobpoint?: InputMaybe<StringOperationFilterInput>
  friendlyId?: InputMaybe<ComparableInt64OperationFilterInput>
  id?: InputMaybe<ComparableGuidOperationFilterInput>
  inventoryLocation?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>
  isPlaced?: InputMaybe<BooleanOperationFilterInput>
  locationId?: InputMaybe<ComparableNullableOfInt64OperationFilterInput>
  notes?: InputMaybe<StringOperationFilterInput>
  or?: InputMaybe<Array<OrderFilterInput>>
  orderDate?: InputMaybe<ComparableDateTimeOperationFilterInput>
  orderTypeId?: InputMaybe<ComparableInt32OperationFilterInput>
  originatingOrderId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>
  parentOrderId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>
  podateCreated?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>
  podatePlaced?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>
  porequisitioner?: InputMaybe<StringOperationFilterInput>
  poshipTo?: InputMaybe<StringOperationFilterInput>
  poto?: InputMaybe<StringOperationFilterInput>
  referenceNumber?: InputMaybe<StringOperationFilterInput>
  refundedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>
  remoteReferenceNumber?: InputMaybe<StringOperationFilterInput>
  requestedDeliveryDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>
  requestedShippingMethodId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>
  requiredByDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>
  shipVia?: InputMaybe<StringOperationFilterInput>
  shipping?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>
  shippingAddressId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>
  sourceChannelId?: InputMaybe<ComparableInt32OperationFilterInput>
  sourceLocationId?: InputMaybe<ComparableNullableOfInt64OperationFilterInput>
  status?: InputMaybe<ComparableInt32OperationFilterInput>
  subTotal?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>
  supplierId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>
  tax?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>
  terms?: InputMaybe<StringOperationFilterInput>
  title?: InputMaybe<StringOperationFilterInput>
  total?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>
  workFlowId?: InputMaybe<ComparableNullableOfInt64OperationFilterInput>
}

export type OrderSortInput = {
  accountChannelTransactionId?: InputMaybe<SortEnumType>
  accountId?: InputMaybe<SortEnumType>
  anticipatedDeliveryDate?: InputMaybe<SortEnumType>
  auditUser?: InputMaybe<SortEnumType>
  batchNumber?: InputMaybe<SortEnumType>
  billingAddressId?: InputMaybe<SortEnumType>
  cancelDate?: InputMaybe<SortEnumType>
  confirmingTo?: InputMaybe<SortEnumType>
  customerId?: InputMaybe<SortEnumType>
  dateCreated?: InputMaybe<SortEnumType>
  dateModified?: InputMaybe<SortEnumType>
  deleted?: InputMaybe<SortEnumType>
  deliveryNotes?: InputMaybe<SortEnumType>
  destinationChannelId?: InputMaybe<SortEnumType>
  destinationLocationId?: InputMaybe<SortEnumType>
  expirationDate?: InputMaybe<SortEnumType>
  fobpoint?: InputMaybe<SortEnumType>
  friendlyId?: InputMaybe<SortEnumType>
  id?: InputMaybe<SortEnumType>
  inventoryLocation?: InputMaybe<SortEnumType>
  isPlaced?: InputMaybe<SortEnumType>
  locationId?: InputMaybe<SortEnumType>
  notes?: InputMaybe<SortEnumType>
  orderDate?: InputMaybe<SortEnumType>
  orderTypeId?: InputMaybe<SortEnumType>
  originatingOrderId?: InputMaybe<SortEnumType>
  parentOrderId?: InputMaybe<SortEnumType>
  podateCreated?: InputMaybe<SortEnumType>
  podatePlaced?: InputMaybe<SortEnumType>
  porequisitioner?: InputMaybe<SortEnumType>
  poshipTo?: InputMaybe<SortEnumType>
  poto?: InputMaybe<SortEnumType>
  referenceNumber?: InputMaybe<SortEnumType>
  refundedDate?: InputMaybe<SortEnumType>
  remoteReferenceNumber?: InputMaybe<SortEnumType>
  requestedDeliveryDate?: InputMaybe<SortEnumType>
  requestedShippingMethodId?: InputMaybe<SortEnumType>
  requiredByDate?: InputMaybe<SortEnumType>
  shipVia?: InputMaybe<SortEnumType>
  shipping?: InputMaybe<SortEnumType>
  shippingAddressId?: InputMaybe<SortEnumType>
  sourceChannelId?: InputMaybe<SortEnumType>
  sourceLocationId?: InputMaybe<SortEnumType>
  status?: InputMaybe<SortEnumType>
  subTotal?: InputMaybe<SortEnumType>
  supplierId?: InputMaybe<SortEnumType>
  tax?: InputMaybe<SortEnumType>
  terms?: InputMaybe<SortEnumType>
  title?: InputMaybe<SortEnumType>
  total?: InputMaybe<SortEnumType>
  workFlowId?: InputMaybe<SortEnumType>
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output']
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>
}

export type PaymentTypeDto = {
  __typename?: 'PaymentTypeDto'
  id: Scalars['Long']['output']
  name?: Maybe<Scalars['String']['output']>
  saveAction: SaveAction
  tenderTypeId?: Maybe<Scalars['Int']['output']>
}

export type PaymentTypeDtoInput = {
  id: Scalars['Long']['input']
  name?: InputMaybe<Scalars['String']['input']>
  saveAction: SaveAction
  tenderTypeId?: InputMaybe<Scalars['Int']['input']>
}

export type PosModuleConfiguration = {
  __typename?: 'PosModuleConfiguration'
  aac?: Maybe<Scalars['String']['output']>
  aacWebServiceUrl?: Maybe<Scalars['String']['output']>
  access?: Maybe<Scalars['String']['output']>
  accountId: Scalars['Long']['output']
  creditCardAuthType: CreditCardAuthType
  daysForReturn?: Maybe<Scalars['Int']['output']>
  deviceExists: Scalars['Boolean']['output']
  docNumberEnd?: Maybe<Scalars['String']['output']>
  docNumberExcludedEndings?: Maybe<Scalars['String']['output']>
  docNumberOverrideList?: Maybe<Scalars['String']['output']>
  docNumberStart?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  imageIcons?: Maybe<Scalars['String']['output']>
  isGsa: Scalars['Boolean']['output']
  lineItemMaxExtendedPrice: Scalars['Decimal']['output']
  lineItemMaxQuantity: Scalars['Decimal']['output']
  locations?: Maybe<Array<Maybe<PosModuleConfigurationLocation>>>
  logo?: Maybe<Scalars['String']['output']>
  manualPhoneNumber?: Maybe<Scalars['String']['output']>
  message?: Maybe<Scalars['String']['output']>
  module?: Maybe<Module>
  orderLocation?: Maybe<PosModuleConfigurationLocation>
  password?: Maybe<Scalars['String']['output']>
  paymentTypes?: Maybe<Array<Maybe<PaymentTypeDto>>>
  printSaleXsltUrl?: Maybe<Scalars['String']['output']>
  returnLocation?: Maybe<PosModuleConfigurationLocation>
  username?: Maybe<Scalars['String']['output']>
  validBinCodes?: Maybe<Scalars['String']['output']>
  webServiceUrl?: Maybe<Scalars['String']['output']>
}

export type PosModuleConfigurationLocation = {
  __typename?: 'PosModuleConfigurationLocation'
  aac?: Maybe<Scalars['String']['output']>
  access?: Maybe<Scalars['String']['output']>
  daysForReturn?: Maybe<Scalars['Int']['output']>
  hmmsId?: Maybe<Scalars['Int']['output']>
  hmmsLocationId?: Maybe<Scalars['String']['output']>
  id: Scalars['Long']['output']
  logo?: Maybe<Scalars['String']['output']>
  message?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  subCodes?: Maybe<Array<Maybe<PosModuleConfigurationLocationSubCode>>>
  timeZone?: Maybe<Scalars['String']['output']>
}

export type PosModuleConfigurationLocationInput = {
  aac?: InputMaybe<Scalars['String']['input']>
  access?: InputMaybe<Scalars['String']['input']>
  daysForReturn?: InputMaybe<Scalars['Int']['input']>
  hmmsId?: InputMaybe<Scalars['Int']['input']>
  hmmsLocationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['Long']['input']
  logo?: InputMaybe<Scalars['String']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  subCodes?: InputMaybe<
    Array<InputMaybe<PosModuleConfigurationLocationSubCodeInput>>
  >
  timeZone?: InputMaybe<Scalars['String']['input']>
}

export type PosModuleConfigurationLocationSubCode = {
  __typename?: 'PosModuleConfigurationLocationSubCode'
  code?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  name?: Maybe<Scalars['String']['output']>
}

export type PosModuleConfigurationLocationSubCodeInput = {
  code?: InputMaybe<Scalars['String']['input']>
  id: Scalars['UUID']['input']
  name?: InputMaybe<Scalars['String']['input']>
}

export type PosRoutingUpdateInput = {
  accountId: Scalars['Long']['input']
  orderLocationId: Scalars['Long']['input']
  returnLocationId: Scalars['Long']['input']
}

export type PosTestCreditCardCreateInput = {
  accountId: Scalars['Long']['input']
  card?: InputMaybe<CreditCardDtoInput>
}

export type PosTestCreditCardDeleteInput = {
  accountId: Scalars['Long']['input']
  creditCardId: Scalars['UUID']['input']
}

export type PrintOrderDto = {
  __typename?: 'PrintOrderDto'
  customer?: Maybe<Customer>
  entries?: Maybe<Array<Maybe<Entry>>>
  order?: Maybe<Order>
  sourceLocation?: Maybe<Location>
}

export type Query = {
  __typename?: 'Query'
  accountModules?: Maybe<Array<Maybe<AccountModuleDto>>>
  accountSearch?: Maybe<Array<Maybe<AccountSearchDto>>>
  childCustomers?: Maybe<Array<Maybe<CustomerSearchDto>>>
  globalCustomers?: Maybe<Array<Maybe<CustomerSearchDto>>>
  itemBySku?: Maybe<ItemDto>
  locations?: Maybe<Array<Maybe<LocationDto>>>
  me?: Maybe<Me>
  moduleConfigurations?: Maybe<ModuleConfigurationsResponse>
  posTestCreditCards?: Maybe<Array<Maybe<CreditCardDto>>>
  printOrders?: Maybe<Array<Maybe<PrintOrderDto>>>
  reasonCodeTypes?: Maybe<Array<Maybe<ReasonCodeTypeDto>>>
  reports?: Maybe<Array<Maybe<ReportDto>>>
  templateModules?: Maybe<Array<Maybe<Module>>>
  tenderTypes?: Maybe<Array<Maybe<TenderTypeDto>>>
  testies?: Maybe<TestiesConnection>
  timezones?: Maybe<Array<Maybe<TimeZoneDto>>>
  users?: Maybe<Array<Maybe<UserDto>>>
  usersByDodaacBeaBesa?: Maybe<Array<Maybe<UserDto>>>
  workflowOrderStatus?: Maybe<WorkflowOrderStatus>
  workflowSupport?: Maybe<WorkflowSupportDto>
  workflowSupportOrder?: Maybe<WorkflowSupportOrderIdk>
}

export type QueryChildCustomersArgs = {
  accountId: Scalars['Long']['input']
  parentId: Scalars['UUID']['input']
}

export type QueryGlobalCustomersArgs = {
  accountId: Scalars['Long']['input']
}

export type QueryItemBySkuArgs = {
  input?: InputMaybe<ItemBySkuInput>
}

export type QueryLocationsArgs = {
  accountId: Scalars['Long']['input']
}

export type QueryModuleConfigurationsArgs = {
  accountId: Scalars['Long']['input']
}

export type QueryPosTestCreditCardsArgs = {
  accountId: Scalars['Long']['input']
}

export type QueryPrintOrdersArgs = {
  input?: InputMaybe<GetPrintOrdersInput>
}

export type QueryTestiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  order?: InputMaybe<Array<OrderSortInput>>
  where?: InputMaybe<OrderFilterInput>
}

export type QueryUsersArgs = {
  accountId: Scalars['Long']['input']
}

export type QueryUsersByDodaacBeaBesaArgs = {
  input?: InputMaybe<UsersByDodaacBeaBesaInput>
}

export type QueryWorkflowOrderStatusArgs = {
  orderId: Scalars['UUID']['input']
}

export type QueryWorkflowSupportArgs = {
  accountId: Scalars['Long']['input']
}

export type QueryWorkflowSupportOrderArgs = {
  orderId: Scalars['UUID']['input']
}

export type ReasonCodeDto = {
  __typename?: 'ReasonCodeDto'
  code?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  reasonCodeTypeId: Scalars['Int']['output']
  saveAction: SaveAction
}

export type ReasonCodeDtoInput = {
  code?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id: Scalars['UUID']['input']
  reasonCodeTypeId: Scalars['Int']['input']
  saveAction: SaveAction
}

export type ReasonCodeTypeDto = {
  __typename?: 'ReasonCodeTypeDto'
  id: Scalars['Int']['output']
  name?: Maybe<Scalars['String']['output']>
}

export type ReportDto = {
  __typename?: 'ReportDto'
  id: Scalars['UUID']['output']
  name?: Maybe<Scalars['String']['output']>
}

export enum SaveAction {
  Create = 'CREATE',
  Delete = 'DELETE',
  None = 'NONE',
  Update = 'UPDATE',
}

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum StatusEnum {
  Error = 'ERROR',
  Healthy = 'HEALTHY',
  None = 'NONE',
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  eq?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  ncontains?: InputMaybe<Scalars['String']['input']>
  nendsWith?: InputMaybe<Scalars['String']['input']>
  neq?: InputMaybe<Scalars['String']['input']>
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  nstartsWith?: InputMaybe<Scalars['String']['input']>
  or?: InputMaybe<Array<StringOperationFilterInput>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type TenderTypeDto = {
  __typename?: 'TenderTypeDto'
  id: Scalars['Int']['output']
  name?: Maybe<Scalars['String']['output']>
}

/** A connection to a list of items. */
export type TestiesConnection = {
  __typename?: 'TestiesConnection'
  /** A list of edges. */
  edges?: Maybe<Array<TestiesEdge>>
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Order>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type TestiesEdge = {
  __typename?: 'TestiesEdge'
  accessCode?: Maybe<Scalars['String']['output']>
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  dodaacBeaBesa?: Maybe<Scalars['String']['output']>
  /** The item at the end of the edge. */
  node?: Maybe<Order>
  workflowStatus?: Maybe<WorkflowOrderStatus>
}

export type TimeZoneDto = {
  __typename?: 'TimeZoneDto'
  id?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export enum TransactionType {
  Referral = 'REFERRAL',
  WalkInWalkOut = 'WALK_IN_WALK_OUT',
}

export type UpdateCashierIdInput = {
  accountId: Scalars['Long']['input']
  cashierId?: InputMaybe<Scalars['String']['input']>
  userId: Scalars['Long']['input']
}

export type UpdateInventoryModuleConfigurationInput = {
  accountId: Scalars['Long']['input']
  id: Scalars['Int']['input']
  reasonCodes?: InputMaybe<Array<InputMaybe<ReasonCodeDtoInput>>>
}

export type UpdatePosModuleConfigurationInput = {
  aac?: InputMaybe<Scalars['String']['input']>
  aacWebServiceUrl?: InputMaybe<Scalars['String']['input']>
  access?: InputMaybe<Scalars['String']['input']>
  accountId: Scalars['Long']['input']
  creditCardAuthType: CreditCardAuthType
  daysForReturn?: InputMaybe<Scalars['Int']['input']>
  deviceExists: Scalars['Boolean']['input']
  docNumberEnd?: InputMaybe<Scalars['String']['input']>
  docNumberExcludedEndings?: InputMaybe<Scalars['String']['input']>
  docNumberOverrideList?: InputMaybe<Scalars['String']['input']>
  docNumberStart?: InputMaybe<Scalars['String']['input']>
  id: Scalars['Int']['input']
  imageIcons?: InputMaybe<Scalars['String']['input']>
  isGsa: Scalars['Boolean']['input']
  lineItemMaxExtendedPrice: Scalars['Decimal']['input']
  lineItemMaxQuantity: Scalars['Decimal']['input']
  locations?: InputMaybe<Array<InputMaybe<PosModuleConfigurationLocationInput>>>
  logo?: InputMaybe<Scalars['String']['input']>
  manualPhoneNumber?: InputMaybe<Scalars['String']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  module?: InputMaybe<ModuleInput>
  orderLocation?: InputMaybe<PosModuleConfigurationLocationInput>
  password?: InputMaybe<Scalars['String']['input']>
  paymentTypes?: InputMaybe<Array<InputMaybe<PaymentTypeDtoInput>>>
  printSaleXsltUrl?: InputMaybe<Scalars['String']['input']>
  returnLocation?: InputMaybe<PosModuleConfigurationLocationInput>
  username?: InputMaybe<Scalars['String']['input']>
  validBinCodes?: InputMaybe<Scalars['String']['input']>
  webServiceUrl?: InputMaybe<Scalars['String']['input']>
}

export type UserDto = {
  __typename?: 'UserDto'
  active: Scalars['Boolean']['output']
  cashierId?: Maybe<Scalars['String']['output']>
  dodaacBeaBesas?: Maybe<Array<Maybe<DodaacBeaBesa>>>
  id: Scalars['Long']['output']
  locations?: Maybe<Array<Maybe<LocationDto>>>
  locked: Scalars['Boolean']['output']
  name?: Maybe<Scalars['String']['output']>
  roles?: Maybe<Array<Maybe<UserRolesDataLoaderRole>>>
}

export type UserRolesDataLoaderRole = {
  __typename?: 'UserRolesDataLoaderRole'
  id: Scalars['UUID']['output']
  role?: Maybe<Scalars['String']['output']>
}

export type UsersAddToDodaacBeaBesaInput = {
  accountId: Scalars['Long']['input']
  dodaacBeaBesa?: InputMaybe<Scalars['String']['input']>
  userIds?: InputMaybe<Array<Scalars['Long']['input']>>
}

export type UsersByDodaacBeaBesaInput = {
  accountId: Scalars['Long']['input']
  dodaacBeaBesa?: InputMaybe<Scalars['String']['input']>
}

export type UsersRemoveFromDodaacBeaBesaInput = {
  dodaacBeaBesa?: InputMaybe<Scalars['String']['input']>
  userId: Scalars['Long']['input']
}

export enum WorkflowErrorCodes {
  NoFundsManager = 'NO_FUNDS_MANAGER',
  Unknown = 'UNKNOWN',
}

export type WorkflowInvokeInput = {
  orderId: Scalars['UUID']['input']
}

export type WorkflowInvokeResponse = {
  __typename?: 'WorkflowInvokeResponse'
  success: Scalars['Boolean']['output']
}

export type WorkflowModuleConfiguration = {
  __typename?: 'WorkflowModuleConfiguration'
  defaultValues?: Maybe<WorkflowModuleConfigurationDefaultValues>
  enabled: Scalars['Boolean']['output']
  id: Scalars['Int']['output']
  module?: Maybe<Module>
  serviceUrl?: Maybe<Scalars['String']['output']>
}

export type WorkflowModuleConfigurationDefaultValues = {
  __typename?: 'WorkflowModuleConfigurationDefaultValues'
  serviceUrl?: Maybe<Scalars['String']['output']>
}

export type WorkflowModuleConfigurationUpdateInput = {
  accountId: Scalars['Long']['input']
  enabled: Scalars['Boolean']['input']
  serviceUrl?: InputMaybe<Scalars['String']['input']>
}

export type WorkflowOrderStatus = {
  __typename?: 'WorkflowOrderStatus'
  currentStep?: Maybe<WorkflowOrderStatus>
  errorCode: WorkflowErrorCodes
  id: Scalars['UUID']['output']
  isComplete: Scalars['Boolean']['output']
  rawError?: Maybe<Scalars['String']['output']>
  status: StatusEnum
  stepInformation?: Maybe<ApprovalStepInformation>
}

export type WorkflowOrderStatusCurrentStepArgs = {
  stepName?: InputMaybe<Scalars['String']['input']>
}

export type WorkflowSupportDto = {
  __typename?: 'WorkflowSupportDto'
  orders?: Maybe<Array<Maybe<WorkflowSupportOrderDto>>>
  ordersTotal: Scalars['Int']['output']
  pluginVersion?: Maybe<Scalars['String']['output']>
  workflowConfiguration?: Maybe<WorkflowModuleConfiguration>
}

export type WorkflowSupportOrderDto = {
  __typename?: 'WorkflowSupportOrderDto'
  accessCode?: Maybe<Scalars['String']['output']>
  approvalPercentComplete?: Maybe<Scalars['Float']['output']>
  approvalStepsCompletedCount: Scalars['Int']['output']
  approvelStepsTotalCount: Scalars['Int']['output']
  created?: Maybe<Scalars['String']['output']>
  dodaacBeaBesa?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  orderStatus?: Maybe<Scalars['String']['output']>
  rawAttributes?: Maybe<Array<Maybe<Attribute2>>>
  rawEvents?: Maybe<Array<Maybe<Event>>>
  referenceNumber?: Maybe<Scalars['String']['output']>
  workflowStatus?: Maybe<WorkflowOrderStatus>
}

export type WorkflowSupportOrderIdk = {
  __typename?: 'WorkflowSupportOrderIdk'
  events?: Maybe<Array<Maybe<EventIdk>>>
  histories?: Maybe<Array<Maybe<HistoryIdk>>>
  referenceNumber?: Maybe<Scalars['String']['output']>
  runCount: Scalars['Int']['output']
}

export type CreateAccountModuleMutationVariables = Exact<{
  input: CreateAccountModuleInput
}>

export type CreateAccountModuleMutation = {
  __typename?: 'Mutation'
  createAccountModule: boolean
}

export type ModuleConfigurationsQueryVariables = Exact<{
  accountId: Scalars['Long']['input']
}>

export type ModuleConfigurationsQuery = {
  __typename?: 'Query'
  moduleConfigurations?: {
    __typename?: 'ModuleConfigurationsResponse'
    accountName?: string | null
    configurations?: Array<
      | {
          __typename: 'InventoryModuleConfiguration'
          id: number
          accountId: any
          module?: {
            __typename?: 'Module'
            id: number
            code?: string | null
          } | null
          reasonCodes?: Array<{
            __typename?: 'ReasonCodeDto'
            id: any
            code?: string | null
            description?: string | null
            reasonCodeTypeId: number
            saveAction: SaveAction
          } | null> | null
        }
      | {
          __typename: 'LocationsModuleConfiguration'
          id: number
          module?: {
            __typename?: 'Module'
            id: number
            code?: string | null
          } | null
        }
      | {
          __typename: 'NoModuleConfiguration'
          id: number
          enabled: boolean
          message?: string | null
          module?: {
            __typename?: 'Module'
            id: number
            code?: string | null
          } | null
        }
      | {
          __typename: 'PosModuleConfiguration'
          id: number
          isGsa: boolean
          accountId: any
          logo?: string | null
          printSaleXsltUrl?: string | null
          message?: string | null
          aac?: string | null
          access?: string | null
          aacWebServiceUrl?: string | null
          webServiceUrl?: string | null
          creditCardAuthType: CreditCardAuthType
          username?: string | null
          password?: string | null
          manualPhoneNumber?: string | null
          validBinCodes?: string | null
          lineItemMaxQuantity: any
          lineItemMaxExtendedPrice: any
          daysForReturn?: number | null
          deviceExists: boolean
          imageIcons?: string | null
          docNumberStart?: string | null
          docNumberEnd?: string | null
          docNumberExcludedEndings?: string | null
          docNumberOverrideList?: string | null
          module?: {
            __typename?: 'Module'
            id: number
            code?: string | null
          } | null
          locations?: Array<{
            __typename?: 'PosModuleConfigurationLocation'
            id: any
            name?: string | null
            logo?: string | null
            message?: string | null
            timeZone?: string | null
            aac?: string | null
            access?: string | null
            daysForReturn?: number | null
            hmmsId?: number | null
            hmmsLocationId?: string | null
            subCodes?: Array<{
              __typename?: 'PosModuleConfigurationLocationSubCode'
              id: any
              name?: string | null
              code?: string | null
            } | null> | null
          } | null> | null
          paymentTypes?: Array<{
            __typename?: 'PaymentTypeDto'
            id: any
            name?: string | null
            tenderTypeId?: number | null
            saveAction: SaveAction
          } | null> | null
          returnLocation?: {
            __typename?: 'PosModuleConfigurationLocation'
            id: any
            name?: string | null
          } | null
          orderLocation?: {
            __typename?: 'PosModuleConfigurationLocation'
            id: any
            name?: string | null
          } | null
        }
      | {
          __typename: 'WorkflowModuleConfiguration'
          id: number
          enabled: boolean
          serviceUrl?: string | null
          module?: {
            __typename?: 'Module'
            id: number
            code?: string | null
          } | null
          defaultValues?: {
            __typename?: 'WorkflowModuleConfigurationDefaultValues'
            serviceUrl?: string | null
          } | null
        }
      | null
    > | null
  } | null
}

export type GetAccountModulesQueryVariables = Exact<{ [key: string]: never }>

export type GetAccountModulesQuery = {
  __typename?: 'Query'
  accountModules?: Array<{
    __typename?: 'AccountModuleDto'
    id: number
    accountId: any
    accountName?: string | null
    modules?: Array<{
      __typename?: 'Module'
      id: number
      code?: string | null
    } | null> | null
  } | null> | null
}

export type AccountSearchQueryVariables = Exact<{ [key: string]: never }>

export type AccountSearchQuery = {
  __typename?: 'Query'
  accountSearch?: Array<{
    __typename?: 'AccountSearchDto'
    id: any
    name?: string | null
  } | null> | null
}

export type LocationsQueryVariables = Exact<{
  accountId: Scalars['Long']['input']
}>

export type LocationsQuery = {
  __typename?: 'Query'
  locations?: Array<{
    __typename?: 'LocationDto'
    id: any
    name?: string | null
  } | null> | null
}

export type UpdateInventoryModuleConfigurationMutationVariables = Exact<{
  input: UpdateInventoryModuleConfigurationInput
}>

export type UpdateInventoryModuleConfigurationMutation = {
  __typename?: 'Mutation'
  updateInventoryModuleConfiguration: boolean
}

export type UpdateCashierIdMutationVariables = Exact<{
  input: UpdateCashierIdInput
}>

export type UpdateCashierIdMutation = {
  __typename?: 'Mutation'
  updateCashierId?: {
    __typename?: 'UserDto'
    id: any
    cashierId?: string | null
  } | null
}

export type LocationsGroupedByLocationQueryVariables = Exact<{
  accountId: Scalars['Long']['input']
}>

export type LocationsGroupedByLocationQuery = {
  __typename?: 'Query'
  locations?: Array<{
    __typename?: 'LocationDto'
    id: any
    name?: string | null
    users?: Array<{
      __typename?: 'UserDto'
      id: any
      cashierId?: string | null
      name?: string | null
      active: boolean
      locked: boolean
    } | null> | null
  } | null> | null
}

export type LocationsGroupedByUsersQueryVariables = Exact<{
  accountId: Scalars['Long']['input']
}>

export type LocationsGroupedByUsersQuery = {
  __typename?: 'Query'
  users?: Array<{
    __typename?: 'UserDto'
    id: any
    name?: string | null
    locations?: Array<{
      __typename?: 'LocationDto'
      id: any
      name?: string | null
    } | null> | null
  } | null> | null
}

export type AssociateUsersToLocationsMutationVariables = Exact<{
  input: AssociateUsersToLocationsInput
}>

export type AssociateUsersToLocationsMutation = {
  __typename?: 'Mutation'
  associateUsersToLocations: boolean
}

export type DisassociateUsersToLocationsMutationVariables = Exact<{
  input: DisassociateUsersToLocationsInput
}>

export type DisassociateUsersToLocationsMutation = {
  __typename?: 'Mutation'
  disassociateUsersToLocations: boolean
}

export type CreatePosLocationDeviceMutationVariables = Exact<{
  input: AccountIdInput
}>

export type CreatePosLocationDeviceMutation = {
  __typename?: 'Mutation'
  createPosLocationDevice?: {
    __typename?: 'ModuleConfigurationsResponse'
    configurations?: Array<
      | { __typename?: 'InventoryModuleConfiguration' }
      | { __typename?: 'LocationsModuleConfiguration' }
      | { __typename?: 'NoModuleConfiguration' }
      | {
          __typename?: 'PosModuleConfiguration'
          id: number
          deviceExists: boolean
        }
      | { __typename?: 'WorkflowModuleConfiguration' }
      | null
    > | null
  } | null
}

export type CreateGsaAttributeMutationVariables = Exact<{
  input: AccountIdInput
}>

export type CreateGsaAttributeMutation = {
  __typename?: 'Mutation'
  createGsaAttribute?: {
    __typename?: 'ModuleConfigurationsResponse'
    configurations?: Array<
      | { __typename?: 'InventoryModuleConfiguration' }
      | { __typename?: 'LocationsModuleConfiguration' }
      | { __typename?: 'NoModuleConfiguration' }
      | { __typename?: 'PosModuleConfiguration'; id: number; isGsa: boolean }
      | { __typename?: 'WorkflowModuleConfiguration' }
      | null
    > | null
  } | null
}

export type LocationHmmsUpdateMutationVariables = Exact<{
  input: LocationHmmsUpdateInput
}>

export type LocationHmmsUpdateMutation = {
  __typename?: 'Mutation'
  locationHmmsUpdate?: Array<{
    __typename?: 'PosModuleConfigurationLocation'
    id: any
    hmmsId?: number | null
    hmmsLocationId?: string | null
  } | null> | null
}

export type LocationHmmsDeleteMutationVariables = Exact<{
  input: LocationHmmsUpdateInput
}>

export type LocationHmmsDeleteMutation = {
  __typename?: 'Mutation'
  locationHmmsDelete?: Array<{
    __typename?: 'PosModuleConfigurationLocation'
    id: any
    hmmsId?: number | null
    hmmsLocationId?: string | null
  } | null> | null
}

export type UpdatePosModuleConfigurationMutationVariables = Exact<{
  input: UpdatePosModuleConfigurationInput
}>

export type UpdatePosModuleConfigurationMutation = {
  __typename?: 'Mutation'
  updatePosModuleConfiguration: boolean
}

export type PosRoutingDeleteMutationVariables = Exact<{
  input: AccountIdInput
}>

export type PosRoutingDeleteMutation = {
  __typename?: 'Mutation'
  posRoutingDelete?: {
    __typename?: 'PosModuleConfiguration'
    id: number
    returnLocation?: {
      __typename?: 'PosModuleConfigurationLocation'
      id: any
      name?: string | null
    } | null
    orderLocation?: {
      __typename?: 'PosModuleConfigurationLocation'
      id: any
      name?: string | null
    } | null
  } | null
}

export type PosRoutingUpdateMutationVariables = Exact<{
  input: PosRoutingUpdateInput
}>

export type PosRoutingUpdateMutation = {
  __typename?: 'Mutation'
  posRoutingUpdate?: {
    __typename?: 'PosModuleConfiguration'
    id: number
    returnLocation?: {
      __typename?: 'PosModuleConfigurationLocation'
      id: any
      name?: string | null
    } | null
    orderLocation?: {
      __typename?: 'PosModuleConfigurationLocation'
      id: any
      name?: string | null
    } | null
  } | null
}

export type GetPosTestCreditCardsQueryVariables = Exact<{
  accountId: Scalars['Long']['input']
}>

export type GetPosTestCreditCardsQuery = {
  __typename?: 'Query'
  posTestCreditCards?: Array<{
    __typename?: 'CreditCardDto'
    id: any
    name?: string | null
    value?: string | null
  } | null> | null
}

export type PosTestCreditCardDeleteMutationVariables = Exact<{
  input: PosTestCreditCardDeleteInput
}>

export type PosTestCreditCardDeleteMutation = {
  __typename?: 'Mutation'
  posTestCreditCardDelete: boolean
}

export type PostTestCreditCardCreateMutationVariables = Exact<{
  input: PosTestCreditCardCreateInput
}>

export type PostTestCreditCardCreateMutation = {
  __typename?: 'Mutation'
  posTestCreditCardCreate?: { __typename?: 'CreditCardDto'; id: any } | null
}

export type WorkflowModuleConfigurationUpdateMutationVariables = Exact<{
  input: WorkflowModuleConfigurationUpdateInput
}>

export type WorkflowModuleConfigurationUpdateMutation = {
  __typename?: 'Mutation'
  workflowModuleConfigurationUpdate: boolean
}

export type ReasonCodeTypesQueryVariables = Exact<{ [key: string]: never }>

export type ReasonCodeTypesQuery = {
  __typename?: 'Query'
  reasonCodeTypes?: Array<{
    __typename?: 'ReasonCodeTypeDto'
    id: number
    name?: string | null
  } | null> | null
}

export type CreateTemplateModuleMutationVariables = Exact<{
  code: Scalars['String']['input']
}>

export type CreateTemplateModuleMutation = {
  __typename?: 'Mutation'
  createTemplateModule?: {
    __typename?: 'Module'
    id: number
    code?: string | null
  } | null
}

export type GetTemplateModulesQueryVariables = Exact<{ [key: string]: never }>

export type GetTemplateModulesQuery = {
  __typename?: 'Query'
  templateModules?: Array<{
    __typename?: 'Module'
    id: number
    code?: string | null
  } | null> | null
}

export type TenderTypesQueryVariables = Exact<{ [key: string]: never }>

export type TenderTypesQuery = {
  __typename?: 'Query'
  tenderTypes?: Array<{
    __typename?: 'TenderTypeDto'
    id: number
    name?: string | null
  } | null> | null
}

export type UsersQueryVariables = Exact<{
  accountId: Scalars['Long']['input']
}>

export type UsersQuery = {
  __typename?: 'Query'
  users?: Array<{
    __typename?: 'UserDto'
    id: any
    name?: string | null
  } | null> | null
}

export type UpdateCustomerMutationVariables = Exact<{
  input: CustomerSearchDtoInput
}>

export type UpdateCustomerMutation = {
  __typename?: 'Mutation'
  updateCustomer: boolean
}

export type GetMeQueryVariables = Exact<{ [key: string]: never }>

export type GetMeQuery = {
  __typename?: 'Query'
  me?: {
    __typename?: 'Me'
    id: any
    permissions?: Array<string | null> | null
    roles?: Array<string | null> | null
  } | null
}

export type PrintOrdersQueryVariables = Exact<{
  input: GetPrintOrdersInput
}>

export type PrintOrdersQuery = {
  __typename?: 'Query'
  printOrders?: Array<{
    __typename?: 'PrintOrderDto'
    order?: {
      __typename?: 'Order'
      id: any
      referenceNumber?: string | null
      orderTypeId: number
      remoteReferenceNumber?: string | null
      orderDate: any
      attribute2s?: Array<{
        __typename?: 'Attribute2'
        id: any
        name?: string | null
        value?: string | null
      } | null> | null
    } | null
    sourceLocation?: {
      __typename?: 'Location'
      accountingCustomerNumber?: string | null
      name?: string | null
    } | null
    customer?: { __typename?: 'Customer'; accountNumber?: string | null } | null
    entries?: Array<{
      __typename?: 'Entry'
      id: any
      quantityOnOrder?: any | null
      sku?: string | null
      entryAttributes?: Array<{
        __typename?: 'EntryAttribute'
        id: any
        name?: string | null
        value?: string | null
      } | null> | null
      item?: {
        __typename?: 'Item'
        name?: string | null
        description?: string | null
        attribute1s?: Array<{
          __typename?: 'Attribute1'
          id: any
          name?: string | null
          value?: string | null
        } | null> | null
      } | null
    } | null> | null
  } | null> | null
}

export type WorkflowDeleteOrderStateMutationVariables = Exact<{
  orderId: Scalars['UUID']['input']
}>

export type WorkflowDeleteOrderStateMutation = {
  __typename?: 'Mutation'
  workflowDeleteOrderState: boolean
}

export type TimezonesQueryVariables = Exact<{ [key: string]: never }>

export type TimezonesQuery = {
  __typename?: 'Query'
  timezones?: Array<{
    __typename?: 'TimeZoneDto'
    id?: string | null
    name?: string | null
  } | null> | null
}

export type Template_ModuleFragment = {
  __typename?: 'Module'
  id: number
  code?: string | null
}

export type GetGlobalCustomersQueryVariables = Exact<{
  accountid: Scalars['Long']['input']
}>

export type GetGlobalCustomersQuery = {
  __typename?: 'Query'
  globalCustomers?: Array<{
    __typename?: 'CustomerSearchDto'
    id?: any | null
    accountNumber?: string | null
    company?: string | null
    inactive?: boolean | null
    bOAC?: string | null
    agencyCode?: string | null
  } | null> | null
}

export type GetChildCustomersQueryVariables = Exact<{
  accountid: Scalars['Long']['input']
  parentid: Scalars['UUID']['input']
}>

export type GetChildCustomersQuery = {
  __typename?: 'Query'
  childCustomers?: Array<{
    __typename?: 'CustomerSearchDto'
    id?: any | null
    accountNumber?: string | null
    firstName?: string | null
    inactive?: boolean | null
    jon?: string | null
    fundCode?: string | null
    telephone?: string | null
    cCCustomer?: string | null
    email?: string | null
    altEmail?: string | null
    altEmail2?: string | null
    altEmail3?: string | null
  } | null> | null
}

export type GetLoggedInUsersPermissionsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetLoggedInUsersPermissionsQuery = {
  __typename?: 'Query'
  me?: {
    __typename?: 'Me'
    id: any
    permissions?: Array<string | null> | null
  } | null
}

export type GetLoggedInUsersAccountQueryVariables = Exact<{
  [key: string]: never
}>

export type GetLoggedInUsersAccountQuery = {
  __typename?: 'Query'
  me?: { __typename?: 'Me'; id: any; accountId: any } | null
}

export type ReportsQueryVariables = Exact<{ [key: string]: never }>

export type ReportsQuery = {
  __typename?: 'Query'
  reports?: Array<{
    __typename?: 'ReportDto'
    id: any
    name?: string | null
  } | null> | null
}

export type UsersByDodaacBeaBesaQueryVariables = Exact<{
  input: UsersByDodaacBeaBesaInput
}>

export type UsersByDodaacBeaBesaQuery = {
  __typename?: 'Query'
  usersByDodaacBeaBesa?: Array<{
    __typename?: 'UserDto'
    id: any
    name?: string | null
    dodaacBeaBesas?: Array<{
      __typename?: 'DodaacBeaBesa'
      id: any
      value?: string | null
    } | null> | null
    roles?: Array<{
      __typename?: 'UserRolesDataLoaderRole'
      id: any
      role?: string | null
    } | null> | null
  } | null> | null
}

export type UsersAddToDodaacBeaBesaMutationVariables = Exact<{
  input: UsersAddToDodaacBeaBesaInput
}>

export type UsersAddToDodaacBeaBesaMutation = {
  __typename?: 'Mutation'
  usersAddToDodaacBeaBesa: boolean
}

export type UsersRemoveFromDodaacBeaBesaMutationVariables = Exact<{
  input: UsersRemoveFromDodaacBeaBesaInput
}>

export type UsersRemoveFromDodaacBeaBesaMutation = {
  __typename?: 'Mutation'
  usersRemoveFromDodaacBeaBesa: boolean
}

export type GetWorkflowSupportQueryVariables = Exact<{
  accountId: Scalars['Long']['input']
}>

export type GetWorkflowSupportQuery = {
  __typename?: 'Query'
  workflowSupport?: {
    __typename?: 'WorkflowSupportDto'
    pluginVersion?: string | null
    ordersTotal: number
    workflowConfiguration?: {
      __typename?: 'WorkflowModuleConfiguration'
      id: number
      enabled: boolean
      serviceUrl?: string | null
    } | null
    orders?: Array<{
      __typename?: 'WorkflowSupportOrderDto'
      id: any
      orderStatus?: string | null
      referenceNumber?: string | null
      created?: string | null
      dodaacBeaBesa?: string | null
      accessCode?: string | null
      approvalStepsCompletedCount: number
      approvelStepsTotalCount: number
      approvalPercentComplete?: number | null
      workflowStatus?: {
        __typename?: 'WorkflowOrderStatus'
        id: any
        status: StatusEnum
        errorCode: WorkflowErrorCodes
        rawError?: string | null
      } | null
    } | null> | null
  } | null
}

export type WorkflowInvokeMutationVariables = Exact<{
  orderId: Scalars['UUID']['input']
}>

export type WorkflowInvokeMutation = {
  __typename?: 'Mutation'
  workflowInvoke?: {
    __typename?: 'WorkflowInvokeResponse'
    success: boolean
  } | null
}

export type GetWorkflowSupportOrderQueryVariables = Exact<{
  orderId: Scalars['UUID']['input']
}>

export type GetWorkflowSupportOrderQuery = {
  __typename?: 'Query'
  workflowSupportOrder?: {
    __typename?: 'WorkflowSupportOrderIdk'
    runCount: number
    referenceNumber?: string | null
    histories?: Array<{
      __typename?: 'HistoryIdk'
      id: any
      message?: string | null
      date?: string | null
    } | null> | null
    events?: Array<{
      __typename?: 'EventIdk'
      id: any
      name?: string | null
      data?: string | null
      date?: string | null
    } | null> | null
  } | null
}

export type GetSupportOrderWorkflowGridQueryVariables = Exact<{
  where?: InputMaybe<OrderFilterInput>
  order?: InputMaybe<Array<OrderSortInput> | OrderSortInput>
  first?: InputMaybe<Scalars['Int']['input']>
}>

export type GetSupportOrderWorkflowGridQuery = {
  __typename?: 'Query'
  testies?: {
    __typename: 'TestiesConnection'
    totalCount: number
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
      endCursor?: string | null
    }
    edges?: Array<{
      __typename: 'TestiesEdge'
      dodaacBeaBesa?: string | null
      accessCode?: string | null
      cursor: string
      workflowStatus?: {
        __typename?: 'WorkflowOrderStatus'
        id: any
        status: StatusEnum
        errorCode: WorkflowErrorCodes
        rawError?: string | null
        stepInformation?: {
          __typename?: 'ApprovalStepInformation'
          completeCount: number
          stepTotal: number
          percentComplete?: number | null
        } | null
      } | null
      node?: {
        __typename: 'Order'
        id: any
        accountId: any
        orderTypeId: number
        status: number
        dateCreated: any
        referenceNumber?: string | null
        orderDate: any
        attribute2s?: Array<{
          __typename?: 'Attribute2'
          id: any
          name?: string | null
          value?: string | null
        } | null> | null
        events?: Array<{
          __typename?: 'Event'
          id: any
          name?: string | null
          data?: string | null
        } | null> | null
      } | null
    }> | null
  } | null
}

export const Template_ModuleFragmentDoc = gql`
  fragment TEMPLATE_MODULE on Module {
    id
    code
  }
`
export const CreateAccountModuleDocument = gql`
  mutation createAccountModule($input: CreateAccountModuleInput!) {
    createAccountModule(input: $input)
  }
`
export type CreateAccountModuleMutationFn = Apollo.MutationFunction<
  CreateAccountModuleMutation,
  CreateAccountModuleMutationVariables
>

/**
 * __useCreateAccountModuleMutation__
 *
 * To run a mutation, you first call `useCreateAccountModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountModuleMutation, { data, loading, error }] = useCreateAccountModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAccountModuleMutation,
    CreateAccountModuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateAccountModuleMutation,
    CreateAccountModuleMutationVariables
  >(CreateAccountModuleDocument, options)
}
export type CreateAccountModuleMutationHookResult = ReturnType<
  typeof useCreateAccountModuleMutation
>
export type CreateAccountModuleMutationResult =
  Apollo.MutationResult<CreateAccountModuleMutation>
export type CreateAccountModuleMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountModuleMutation,
  CreateAccountModuleMutationVariables
>
export const ModuleConfigurationsDocument = gql`
  query moduleConfigurations($accountId: Long!) {
    moduleConfigurations(accountId: $accountId) {
      accountName
      configurations {
        __typename
        ... on WorkflowModuleConfiguration {
          id
          enabled
          serviceUrl
          module {
            id
            code
          }
          defaultValues {
            serviceUrl
          }
        }
        ... on NoModuleConfiguration {
          id
          enabled
          message
          module {
            id
            code
          }
        }
        ... on PosModuleConfiguration {
          id
          isGsa
          module {
            id
            code
          }
          accountId
          logo
          printSaleXsltUrl
          message
          aac
          access
          aacWebServiceUrl
          webServiceUrl
          creditCardAuthType
          username
          password
          manualPhoneNumber
          validBinCodes
          lineItemMaxQuantity
          lineItemMaxExtendedPrice
          daysForReturn
          deviceExists
          locations {
            id
            name
            logo
            message
            timeZone
            aac
            access
            daysForReturn
            hmmsId
            hmmsLocationId
            subCodes {
              id
              name
              code
            }
          }
          paymentTypes {
            id
            name
            tenderTypeId
            saveAction
          }
          returnLocation {
            id
            name
          }
          orderLocation {
            id
            name
          }
          imageIcons
          docNumberStart
          docNumberEnd
          docNumberExcludedEndings
          docNumberOverrideList
        }
        ... on LocationsModuleConfiguration {
          id
          module {
            id
            code
          }
        }
        ... on InventoryModuleConfiguration {
          id
          module {
            id
            code
          }
          accountId
          reasonCodes {
            id
            code
            description
            reasonCodeTypeId
            saveAction
          }
        }
      }
    }
  }
`

/**
 * __useModuleConfigurationsQuery__
 *
 * To run a query within a React component, call `useModuleConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModuleConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModuleConfigurationsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useModuleConfigurationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ModuleConfigurationsQuery,
    ModuleConfigurationsQueryVariables
  > &
    (
      | { variables: ModuleConfigurationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ModuleConfigurationsQuery,
    ModuleConfigurationsQueryVariables
  >(ModuleConfigurationsDocument, options)
}
export function useModuleConfigurationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ModuleConfigurationsQuery,
    ModuleConfigurationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ModuleConfigurationsQuery,
    ModuleConfigurationsQueryVariables
  >(ModuleConfigurationsDocument, options)
}
export function useModuleConfigurationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ModuleConfigurationsQuery,
    ModuleConfigurationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ModuleConfigurationsQuery,
    ModuleConfigurationsQueryVariables
  >(ModuleConfigurationsDocument, options)
}
export type ModuleConfigurationsQueryHookResult = ReturnType<
  typeof useModuleConfigurationsQuery
>
export type ModuleConfigurationsLazyQueryHookResult = ReturnType<
  typeof useModuleConfigurationsLazyQuery
>
export type ModuleConfigurationsSuspenseQueryHookResult = ReturnType<
  typeof useModuleConfigurationsSuspenseQuery
>
export type ModuleConfigurationsQueryResult = Apollo.QueryResult<
  ModuleConfigurationsQuery,
  ModuleConfigurationsQueryVariables
>
export const GetAccountModulesDocument = gql`
  query getAccountModules {
    accountModules {
      id
      accountId
      accountName
      modules {
        id
        code
      }
    }
  }
`

/**
 * __useGetAccountModulesQuery__
 *
 * To run a query within a React component, call `useGetAccountModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountModulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountModulesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAccountModulesQuery,
    GetAccountModulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAccountModulesQuery,
    GetAccountModulesQueryVariables
  >(GetAccountModulesDocument, options)
}
export function useGetAccountModulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountModulesQuery,
    GetAccountModulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAccountModulesQuery,
    GetAccountModulesQueryVariables
  >(GetAccountModulesDocument, options)
}
export function useGetAccountModulesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAccountModulesQuery,
    GetAccountModulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetAccountModulesQuery,
    GetAccountModulesQueryVariables
  >(GetAccountModulesDocument, options)
}
export type GetAccountModulesQueryHookResult = ReturnType<
  typeof useGetAccountModulesQuery
>
export type GetAccountModulesLazyQueryHookResult = ReturnType<
  typeof useGetAccountModulesLazyQuery
>
export type GetAccountModulesSuspenseQueryHookResult = ReturnType<
  typeof useGetAccountModulesSuspenseQuery
>
export type GetAccountModulesQueryResult = Apollo.QueryResult<
  GetAccountModulesQuery,
  GetAccountModulesQueryVariables
>
export const AccountSearchDocument = gql`
  query accountSearch {
    accountSearch {
      id
      name
    }
  }
`

/**
 * __useAccountSearchQuery__
 *
 * To run a query within a React component, call `useAccountSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSearchQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccountSearchQuery,
    AccountSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AccountSearchQuery, AccountSearchQueryVariables>(
    AccountSearchDocument,
    options
  )
}
export function useAccountSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountSearchQuery,
    AccountSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AccountSearchQuery, AccountSearchQueryVariables>(
    AccountSearchDocument,
    options
  )
}
export function useAccountSearchSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AccountSearchQuery,
    AccountSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    AccountSearchQuery,
    AccountSearchQueryVariables
  >(AccountSearchDocument, options)
}
export type AccountSearchQueryHookResult = ReturnType<
  typeof useAccountSearchQuery
>
export type AccountSearchLazyQueryHookResult = ReturnType<
  typeof useAccountSearchLazyQuery
>
export type AccountSearchSuspenseQueryHookResult = ReturnType<
  typeof useAccountSearchSuspenseQuery
>
export type AccountSearchQueryResult = Apollo.QueryResult<
  AccountSearchQuery,
  AccountSearchQueryVariables
>
export const LocationsDocument = gql`
  query locations($accountId: Long!) {
    locations(accountId: $accountId) {
      id
      name
    }
  }
`

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LocationsQuery,
    LocationsQueryVariables
  > &
    ({ variables: LocationsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LocationsQuery, LocationsQueryVariables>(
    LocationsDocument,
    options
  )
}
export function useLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LocationsQuery,
    LocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LocationsQuery, LocationsQueryVariables>(
    LocationsDocument,
    options
  )
}
export function useLocationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    LocationsQuery,
    LocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LocationsQuery, LocationsQueryVariables>(
    LocationsDocument,
    options
  )
}
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>
export type LocationsLazyQueryHookResult = ReturnType<
  typeof useLocationsLazyQuery
>
export type LocationsSuspenseQueryHookResult = ReturnType<
  typeof useLocationsSuspenseQuery
>
export type LocationsQueryResult = Apollo.QueryResult<
  LocationsQuery,
  LocationsQueryVariables
>
export const UpdateInventoryModuleConfigurationDocument = gql`
  mutation updateInventoryModuleConfiguration(
    $input: UpdateInventoryModuleConfigurationInput!
  ) {
    updateInventoryModuleConfiguration(input: $input)
  }
`
export type UpdateInventoryModuleConfigurationMutationFn =
  Apollo.MutationFunction<
    UpdateInventoryModuleConfigurationMutation,
    UpdateInventoryModuleConfigurationMutationVariables
  >

/**
 * __useUpdateInventoryModuleConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateInventoryModuleConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInventoryModuleConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInventoryModuleConfigurationMutation, { data, loading, error }] = useUpdateInventoryModuleConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInventoryModuleConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInventoryModuleConfigurationMutation,
    UpdateInventoryModuleConfigurationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateInventoryModuleConfigurationMutation,
    UpdateInventoryModuleConfigurationMutationVariables
  >(UpdateInventoryModuleConfigurationDocument, options)
}
export type UpdateInventoryModuleConfigurationMutationHookResult = ReturnType<
  typeof useUpdateInventoryModuleConfigurationMutation
>
export type UpdateInventoryModuleConfigurationMutationResult =
  Apollo.MutationResult<UpdateInventoryModuleConfigurationMutation>
export type UpdateInventoryModuleConfigurationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateInventoryModuleConfigurationMutation,
    UpdateInventoryModuleConfigurationMutationVariables
  >
export const UpdateCashierIdDocument = gql`
  mutation updateCashierId($input: UpdateCashierIdInput!) {
    updateCashierId(input: $input) {
      id
      cashierId
    }
  }
`
export type UpdateCashierIdMutationFn = Apollo.MutationFunction<
  UpdateCashierIdMutation,
  UpdateCashierIdMutationVariables
>

/**
 * __useUpdateCashierIdMutation__
 *
 * To run a mutation, you first call `useUpdateCashierIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCashierIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCashierIdMutation, { data, loading, error }] = useUpdateCashierIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCashierIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCashierIdMutation,
    UpdateCashierIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCashierIdMutation,
    UpdateCashierIdMutationVariables
  >(UpdateCashierIdDocument, options)
}
export type UpdateCashierIdMutationHookResult = ReturnType<
  typeof useUpdateCashierIdMutation
>
export type UpdateCashierIdMutationResult =
  Apollo.MutationResult<UpdateCashierIdMutation>
export type UpdateCashierIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateCashierIdMutation,
  UpdateCashierIdMutationVariables
>
export const LocationsGroupedByLocationDocument = gql`
  query locationsGroupedByLocation($accountId: Long!) {
    locations(accountId: $accountId) {
      id
      name
      users {
        id
        cashierId
        name
        active
        locked
      }
    }
  }
`

/**
 * __useLocationsGroupedByLocationQuery__
 *
 * To run a query within a React component, call `useLocationsGroupedByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsGroupedByLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsGroupedByLocationQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useLocationsGroupedByLocationQuery(
  baseOptions: Apollo.QueryHookOptions<
    LocationsGroupedByLocationQuery,
    LocationsGroupedByLocationQueryVariables
  > &
    (
      | { variables: LocationsGroupedByLocationQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    LocationsGroupedByLocationQuery,
    LocationsGroupedByLocationQueryVariables
  >(LocationsGroupedByLocationDocument, options)
}
export function useLocationsGroupedByLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LocationsGroupedByLocationQuery,
    LocationsGroupedByLocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LocationsGroupedByLocationQuery,
    LocationsGroupedByLocationQueryVariables
  >(LocationsGroupedByLocationDocument, options)
}
export function useLocationsGroupedByLocationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    LocationsGroupedByLocationQuery,
    LocationsGroupedByLocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    LocationsGroupedByLocationQuery,
    LocationsGroupedByLocationQueryVariables
  >(LocationsGroupedByLocationDocument, options)
}
export type LocationsGroupedByLocationQueryHookResult = ReturnType<
  typeof useLocationsGroupedByLocationQuery
>
export type LocationsGroupedByLocationLazyQueryHookResult = ReturnType<
  typeof useLocationsGroupedByLocationLazyQuery
>
export type LocationsGroupedByLocationSuspenseQueryHookResult = ReturnType<
  typeof useLocationsGroupedByLocationSuspenseQuery
>
export type LocationsGroupedByLocationQueryResult = Apollo.QueryResult<
  LocationsGroupedByLocationQuery,
  LocationsGroupedByLocationQueryVariables
>
export const LocationsGroupedByUsersDocument = gql`
  query locationsGroupedByUsers($accountId: Long!) {
    users(accountId: $accountId) {
      id
      name
      locations {
        id
        name
      }
    }
  }
`

/**
 * __useLocationsGroupedByUsersQuery__
 *
 * To run a query within a React component, call `useLocationsGroupedByUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsGroupedByUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsGroupedByUsersQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useLocationsGroupedByUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    LocationsGroupedByUsersQuery,
    LocationsGroupedByUsersQueryVariables
  > &
    (
      | { variables: LocationsGroupedByUsersQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    LocationsGroupedByUsersQuery,
    LocationsGroupedByUsersQueryVariables
  >(LocationsGroupedByUsersDocument, options)
}
export function useLocationsGroupedByUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LocationsGroupedByUsersQuery,
    LocationsGroupedByUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LocationsGroupedByUsersQuery,
    LocationsGroupedByUsersQueryVariables
  >(LocationsGroupedByUsersDocument, options)
}
export function useLocationsGroupedByUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    LocationsGroupedByUsersQuery,
    LocationsGroupedByUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    LocationsGroupedByUsersQuery,
    LocationsGroupedByUsersQueryVariables
  >(LocationsGroupedByUsersDocument, options)
}
export type LocationsGroupedByUsersQueryHookResult = ReturnType<
  typeof useLocationsGroupedByUsersQuery
>
export type LocationsGroupedByUsersLazyQueryHookResult = ReturnType<
  typeof useLocationsGroupedByUsersLazyQuery
>
export type LocationsGroupedByUsersSuspenseQueryHookResult = ReturnType<
  typeof useLocationsGroupedByUsersSuspenseQuery
>
export type LocationsGroupedByUsersQueryResult = Apollo.QueryResult<
  LocationsGroupedByUsersQuery,
  LocationsGroupedByUsersQueryVariables
>
export const AssociateUsersToLocationsDocument = gql`
  mutation associateUsersToLocations($input: AssociateUsersToLocationsInput!) {
    associateUsersToLocations(input: $input)
  }
`
export type AssociateUsersToLocationsMutationFn = Apollo.MutationFunction<
  AssociateUsersToLocationsMutation,
  AssociateUsersToLocationsMutationVariables
>

/**
 * __useAssociateUsersToLocationsMutation__
 *
 * To run a mutation, you first call `useAssociateUsersToLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateUsersToLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateUsersToLocationsMutation, { data, loading, error }] = useAssociateUsersToLocationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssociateUsersToLocationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssociateUsersToLocationsMutation,
    AssociateUsersToLocationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AssociateUsersToLocationsMutation,
    AssociateUsersToLocationsMutationVariables
  >(AssociateUsersToLocationsDocument, options)
}
export type AssociateUsersToLocationsMutationHookResult = ReturnType<
  typeof useAssociateUsersToLocationsMutation
>
export type AssociateUsersToLocationsMutationResult =
  Apollo.MutationResult<AssociateUsersToLocationsMutation>
export type AssociateUsersToLocationsMutationOptions =
  Apollo.BaseMutationOptions<
    AssociateUsersToLocationsMutation,
    AssociateUsersToLocationsMutationVariables
  >
export const DisassociateUsersToLocationsDocument = gql`
  mutation disassociateUsersToLocations(
    $input: DisassociateUsersToLocationsInput!
  ) {
    disassociateUsersToLocations(input: $input)
  }
`
export type DisassociateUsersToLocationsMutationFn = Apollo.MutationFunction<
  DisassociateUsersToLocationsMutation,
  DisassociateUsersToLocationsMutationVariables
>

/**
 * __useDisassociateUsersToLocationsMutation__
 *
 * To run a mutation, you first call `useDisassociateUsersToLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisassociateUsersToLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disassociateUsersToLocationsMutation, { data, loading, error }] = useDisassociateUsersToLocationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisassociateUsersToLocationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisassociateUsersToLocationsMutation,
    DisassociateUsersToLocationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DisassociateUsersToLocationsMutation,
    DisassociateUsersToLocationsMutationVariables
  >(DisassociateUsersToLocationsDocument, options)
}
export type DisassociateUsersToLocationsMutationHookResult = ReturnType<
  typeof useDisassociateUsersToLocationsMutation
>
export type DisassociateUsersToLocationsMutationResult =
  Apollo.MutationResult<DisassociateUsersToLocationsMutation>
export type DisassociateUsersToLocationsMutationOptions =
  Apollo.BaseMutationOptions<
    DisassociateUsersToLocationsMutation,
    DisassociateUsersToLocationsMutationVariables
  >
export const CreatePosLocationDeviceDocument = gql`
  mutation createPosLocationDevice($input: AccountIdInput!) {
    createPosLocationDevice(input: $input) {
      configurations {
        ... on PosModuleConfiguration {
          id
          deviceExists
        }
      }
    }
  }
`
export type CreatePosLocationDeviceMutationFn = Apollo.MutationFunction<
  CreatePosLocationDeviceMutation,
  CreatePosLocationDeviceMutationVariables
>

/**
 * __useCreatePosLocationDeviceMutation__
 *
 * To run a mutation, you first call `useCreatePosLocationDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePosLocationDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPosLocationDeviceMutation, { data, loading, error }] = useCreatePosLocationDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePosLocationDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePosLocationDeviceMutation,
    CreatePosLocationDeviceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreatePosLocationDeviceMutation,
    CreatePosLocationDeviceMutationVariables
  >(CreatePosLocationDeviceDocument, options)
}
export type CreatePosLocationDeviceMutationHookResult = ReturnType<
  typeof useCreatePosLocationDeviceMutation
>
export type CreatePosLocationDeviceMutationResult =
  Apollo.MutationResult<CreatePosLocationDeviceMutation>
export type CreatePosLocationDeviceMutationOptions = Apollo.BaseMutationOptions<
  CreatePosLocationDeviceMutation,
  CreatePosLocationDeviceMutationVariables
>
export const CreateGsaAttributeDocument = gql`
  mutation createGsaAttribute($input: AccountIdInput!) {
    createGsaAttribute(input: $input) {
      configurations {
        ... on PosModuleConfiguration {
          id
          isGsa
        }
      }
    }
  }
`
export type CreateGsaAttributeMutationFn = Apollo.MutationFunction<
  CreateGsaAttributeMutation,
  CreateGsaAttributeMutationVariables
>

/**
 * __useCreateGsaAttributeMutation__
 *
 * To run a mutation, you first call `useCreateGsaAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGsaAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGsaAttributeMutation, { data, loading, error }] = useCreateGsaAttributeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGsaAttributeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGsaAttributeMutation,
    CreateGsaAttributeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateGsaAttributeMutation,
    CreateGsaAttributeMutationVariables
  >(CreateGsaAttributeDocument, options)
}
export type CreateGsaAttributeMutationHookResult = ReturnType<
  typeof useCreateGsaAttributeMutation
>
export type CreateGsaAttributeMutationResult =
  Apollo.MutationResult<CreateGsaAttributeMutation>
export type CreateGsaAttributeMutationOptions = Apollo.BaseMutationOptions<
  CreateGsaAttributeMutation,
  CreateGsaAttributeMutationVariables
>
export const LocationHmmsUpdateDocument = gql`
  mutation locationHmmsUpdate($input: LocationHmmsUpdateInput!) {
    locationHmmsUpdate(input: $input) {
      id
      hmmsId
      hmmsLocationId
    }
  }
`
export type LocationHmmsUpdateMutationFn = Apollo.MutationFunction<
  LocationHmmsUpdateMutation,
  LocationHmmsUpdateMutationVariables
>

/**
 * __useLocationHmmsUpdateMutation__
 *
 * To run a mutation, you first call `useLocationHmmsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationHmmsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationHmmsUpdateMutation, { data, loading, error }] = useLocationHmmsUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLocationHmmsUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LocationHmmsUpdateMutation,
    LocationHmmsUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LocationHmmsUpdateMutation,
    LocationHmmsUpdateMutationVariables
  >(LocationHmmsUpdateDocument, options)
}
export type LocationHmmsUpdateMutationHookResult = ReturnType<
  typeof useLocationHmmsUpdateMutation
>
export type LocationHmmsUpdateMutationResult =
  Apollo.MutationResult<LocationHmmsUpdateMutation>
export type LocationHmmsUpdateMutationOptions = Apollo.BaseMutationOptions<
  LocationHmmsUpdateMutation,
  LocationHmmsUpdateMutationVariables
>
export const LocationHmmsDeleteDocument = gql`
  mutation locationHmmsDelete($input: LocationHmmsUpdateInput!) {
    locationHmmsDelete(input: $input) {
      id
      hmmsId
      hmmsLocationId
    }
  }
`
export type LocationHmmsDeleteMutationFn = Apollo.MutationFunction<
  LocationHmmsDeleteMutation,
  LocationHmmsDeleteMutationVariables
>

/**
 * __useLocationHmmsDeleteMutation__
 *
 * To run a mutation, you first call `useLocationHmmsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationHmmsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationHmmsDeleteMutation, { data, loading, error }] = useLocationHmmsDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLocationHmmsDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LocationHmmsDeleteMutation,
    LocationHmmsDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LocationHmmsDeleteMutation,
    LocationHmmsDeleteMutationVariables
  >(LocationHmmsDeleteDocument, options)
}
export type LocationHmmsDeleteMutationHookResult = ReturnType<
  typeof useLocationHmmsDeleteMutation
>
export type LocationHmmsDeleteMutationResult =
  Apollo.MutationResult<LocationHmmsDeleteMutation>
export type LocationHmmsDeleteMutationOptions = Apollo.BaseMutationOptions<
  LocationHmmsDeleteMutation,
  LocationHmmsDeleteMutationVariables
>
export const UpdatePosModuleConfigurationDocument = gql`
  mutation updatePosModuleConfiguration(
    $input: UpdatePosModuleConfigurationInput!
  ) {
    updatePosModuleConfiguration(input: $input)
  }
`
export type UpdatePosModuleConfigurationMutationFn = Apollo.MutationFunction<
  UpdatePosModuleConfigurationMutation,
  UpdatePosModuleConfigurationMutationVariables
>

/**
 * __useUpdatePosModuleConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdatePosModuleConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePosModuleConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePosModuleConfigurationMutation, { data, loading, error }] = useUpdatePosModuleConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePosModuleConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePosModuleConfigurationMutation,
    UpdatePosModuleConfigurationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePosModuleConfigurationMutation,
    UpdatePosModuleConfigurationMutationVariables
  >(UpdatePosModuleConfigurationDocument, options)
}
export type UpdatePosModuleConfigurationMutationHookResult = ReturnType<
  typeof useUpdatePosModuleConfigurationMutation
>
export type UpdatePosModuleConfigurationMutationResult =
  Apollo.MutationResult<UpdatePosModuleConfigurationMutation>
export type UpdatePosModuleConfigurationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdatePosModuleConfigurationMutation,
    UpdatePosModuleConfigurationMutationVariables
  >
export const PosRoutingDeleteDocument = gql`
  mutation posRoutingDelete($input: AccountIdInput!) {
    posRoutingDelete(input: $input) {
      id
      returnLocation {
        id
        name
      }
      orderLocation {
        id
        name
      }
    }
  }
`
export type PosRoutingDeleteMutationFn = Apollo.MutationFunction<
  PosRoutingDeleteMutation,
  PosRoutingDeleteMutationVariables
>

/**
 * __usePosRoutingDeleteMutation__
 *
 * To run a mutation, you first call `usePosRoutingDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePosRoutingDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [posRoutingDeleteMutation, { data, loading, error }] = usePosRoutingDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePosRoutingDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PosRoutingDeleteMutation,
    PosRoutingDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PosRoutingDeleteMutation,
    PosRoutingDeleteMutationVariables
  >(PosRoutingDeleteDocument, options)
}
export type PosRoutingDeleteMutationHookResult = ReturnType<
  typeof usePosRoutingDeleteMutation
>
export type PosRoutingDeleteMutationResult =
  Apollo.MutationResult<PosRoutingDeleteMutation>
export type PosRoutingDeleteMutationOptions = Apollo.BaseMutationOptions<
  PosRoutingDeleteMutation,
  PosRoutingDeleteMutationVariables
>
export const PosRoutingUpdateDocument = gql`
  mutation posRoutingUpdate($input: PosRoutingUpdateInput!) {
    posRoutingUpdate(input: $input) {
      id
      returnLocation {
        id
        name
      }
      orderLocation {
        id
        name
      }
    }
  }
`
export type PosRoutingUpdateMutationFn = Apollo.MutationFunction<
  PosRoutingUpdateMutation,
  PosRoutingUpdateMutationVariables
>

/**
 * __usePosRoutingUpdateMutation__
 *
 * To run a mutation, you first call `usePosRoutingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePosRoutingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [posRoutingUpdateMutation, { data, loading, error }] = usePosRoutingUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePosRoutingUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PosRoutingUpdateMutation,
    PosRoutingUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PosRoutingUpdateMutation,
    PosRoutingUpdateMutationVariables
  >(PosRoutingUpdateDocument, options)
}
export type PosRoutingUpdateMutationHookResult = ReturnType<
  typeof usePosRoutingUpdateMutation
>
export type PosRoutingUpdateMutationResult =
  Apollo.MutationResult<PosRoutingUpdateMutation>
export type PosRoutingUpdateMutationOptions = Apollo.BaseMutationOptions<
  PosRoutingUpdateMutation,
  PosRoutingUpdateMutationVariables
>
export const GetPosTestCreditCardsDocument = gql`
  query getPosTestCreditCards($accountId: Long!) {
    posTestCreditCards(accountId: $accountId) {
      id
      name
      value
    }
  }
`

/**
 * __useGetPosTestCreditCardsQuery__
 *
 * To run a query within a React component, call `useGetPosTestCreditCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPosTestCreditCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPosTestCreditCardsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetPosTestCreditCardsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPosTestCreditCardsQuery,
    GetPosTestCreditCardsQueryVariables
  > &
    (
      | { variables: GetPosTestCreditCardsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetPosTestCreditCardsQuery,
    GetPosTestCreditCardsQueryVariables
  >(GetPosTestCreditCardsDocument, options)
}
export function useGetPosTestCreditCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPosTestCreditCardsQuery,
    GetPosTestCreditCardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPosTestCreditCardsQuery,
    GetPosTestCreditCardsQueryVariables
  >(GetPosTestCreditCardsDocument, options)
}
export function useGetPosTestCreditCardsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPosTestCreditCardsQuery,
    GetPosTestCreditCardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetPosTestCreditCardsQuery,
    GetPosTestCreditCardsQueryVariables
  >(GetPosTestCreditCardsDocument, options)
}
export type GetPosTestCreditCardsQueryHookResult = ReturnType<
  typeof useGetPosTestCreditCardsQuery
>
export type GetPosTestCreditCardsLazyQueryHookResult = ReturnType<
  typeof useGetPosTestCreditCardsLazyQuery
>
export type GetPosTestCreditCardsSuspenseQueryHookResult = ReturnType<
  typeof useGetPosTestCreditCardsSuspenseQuery
>
export type GetPosTestCreditCardsQueryResult = Apollo.QueryResult<
  GetPosTestCreditCardsQuery,
  GetPosTestCreditCardsQueryVariables
>
export const PosTestCreditCardDeleteDocument = gql`
  mutation posTestCreditCardDelete($input: PosTestCreditCardDeleteInput!) {
    posTestCreditCardDelete(input: $input)
  }
`
export type PosTestCreditCardDeleteMutationFn = Apollo.MutationFunction<
  PosTestCreditCardDeleteMutation,
  PosTestCreditCardDeleteMutationVariables
>

/**
 * __usePosTestCreditCardDeleteMutation__
 *
 * To run a mutation, you first call `usePosTestCreditCardDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePosTestCreditCardDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [posTestCreditCardDeleteMutation, { data, loading, error }] = usePosTestCreditCardDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePosTestCreditCardDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PosTestCreditCardDeleteMutation,
    PosTestCreditCardDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PosTestCreditCardDeleteMutation,
    PosTestCreditCardDeleteMutationVariables
  >(PosTestCreditCardDeleteDocument, options)
}
export type PosTestCreditCardDeleteMutationHookResult = ReturnType<
  typeof usePosTestCreditCardDeleteMutation
>
export type PosTestCreditCardDeleteMutationResult =
  Apollo.MutationResult<PosTestCreditCardDeleteMutation>
export type PosTestCreditCardDeleteMutationOptions = Apollo.BaseMutationOptions<
  PosTestCreditCardDeleteMutation,
  PosTestCreditCardDeleteMutationVariables
>
export const PostTestCreditCardCreateDocument = gql`
  mutation postTestCreditCardCreate($input: PosTestCreditCardCreateInput!) {
    posTestCreditCardCreate(input: $input) {
      id
    }
  }
`
export type PostTestCreditCardCreateMutationFn = Apollo.MutationFunction<
  PostTestCreditCardCreateMutation,
  PostTestCreditCardCreateMutationVariables
>

/**
 * __usePostTestCreditCardCreateMutation__
 *
 * To run a mutation, you first call `usePostTestCreditCardCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostTestCreditCardCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postTestCreditCardCreateMutation, { data, loading, error }] = usePostTestCreditCardCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostTestCreditCardCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PostTestCreditCardCreateMutation,
    PostTestCreditCardCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PostTestCreditCardCreateMutation,
    PostTestCreditCardCreateMutationVariables
  >(PostTestCreditCardCreateDocument, options)
}
export type PostTestCreditCardCreateMutationHookResult = ReturnType<
  typeof usePostTestCreditCardCreateMutation
>
export type PostTestCreditCardCreateMutationResult =
  Apollo.MutationResult<PostTestCreditCardCreateMutation>
export type PostTestCreditCardCreateMutationOptions =
  Apollo.BaseMutationOptions<
    PostTestCreditCardCreateMutation,
    PostTestCreditCardCreateMutationVariables
  >
export const WorkflowModuleConfigurationUpdateDocument = gql`
  mutation workflowModuleConfigurationUpdate(
    $input: WorkflowModuleConfigurationUpdateInput!
  ) {
    workflowModuleConfigurationUpdate(input: $input)
  }
`
export type WorkflowModuleConfigurationUpdateMutationFn =
  Apollo.MutationFunction<
    WorkflowModuleConfigurationUpdateMutation,
    WorkflowModuleConfigurationUpdateMutationVariables
  >

/**
 * __useWorkflowModuleConfigurationUpdateMutation__
 *
 * To run a mutation, you first call `useWorkflowModuleConfigurationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkflowModuleConfigurationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workflowModuleConfigurationUpdateMutation, { data, loading, error }] = useWorkflowModuleConfigurationUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWorkflowModuleConfigurationUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WorkflowModuleConfigurationUpdateMutation,
    WorkflowModuleConfigurationUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    WorkflowModuleConfigurationUpdateMutation,
    WorkflowModuleConfigurationUpdateMutationVariables
  >(WorkflowModuleConfigurationUpdateDocument, options)
}
export type WorkflowModuleConfigurationUpdateMutationHookResult = ReturnType<
  typeof useWorkflowModuleConfigurationUpdateMutation
>
export type WorkflowModuleConfigurationUpdateMutationResult =
  Apollo.MutationResult<WorkflowModuleConfigurationUpdateMutation>
export type WorkflowModuleConfigurationUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    WorkflowModuleConfigurationUpdateMutation,
    WorkflowModuleConfigurationUpdateMutationVariables
  >
export const ReasonCodeTypesDocument = gql`
  query reasonCodeTypes {
    reasonCodeTypes {
      id
      name
    }
  }
`

/**
 * __useReasonCodeTypesQuery__
 *
 * To run a query within a React component, call `useReasonCodeTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReasonCodeTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReasonCodeTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReasonCodeTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReasonCodeTypesQuery,
    ReasonCodeTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReasonCodeTypesQuery, ReasonCodeTypesQueryVariables>(
    ReasonCodeTypesDocument,
    options
  )
}
export function useReasonCodeTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReasonCodeTypesQuery,
    ReasonCodeTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReasonCodeTypesQuery,
    ReasonCodeTypesQueryVariables
  >(ReasonCodeTypesDocument, options)
}
export function useReasonCodeTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ReasonCodeTypesQuery,
    ReasonCodeTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ReasonCodeTypesQuery,
    ReasonCodeTypesQueryVariables
  >(ReasonCodeTypesDocument, options)
}
export type ReasonCodeTypesQueryHookResult = ReturnType<
  typeof useReasonCodeTypesQuery
>
export type ReasonCodeTypesLazyQueryHookResult = ReturnType<
  typeof useReasonCodeTypesLazyQuery
>
export type ReasonCodeTypesSuspenseQueryHookResult = ReturnType<
  typeof useReasonCodeTypesSuspenseQuery
>
export type ReasonCodeTypesQueryResult = Apollo.QueryResult<
  ReasonCodeTypesQuery,
  ReasonCodeTypesQueryVariables
>
export const CreateTemplateModuleDocument = gql`
  mutation createTemplateModule($code: String!) {
    createTemplateModule(input: { code: $code }) {
      ...TEMPLATE_MODULE
    }
  }
  ${Template_ModuleFragmentDoc}
`
export type CreateTemplateModuleMutationFn = Apollo.MutationFunction<
  CreateTemplateModuleMutation,
  CreateTemplateModuleMutationVariables
>

/**
 * __useCreateTemplateModuleMutation__
 *
 * To run a mutation, you first call `useCreateTemplateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateModuleMutation, { data, loading, error }] = useCreateTemplateModuleMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useCreateTemplateModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTemplateModuleMutation,
    CreateTemplateModuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateTemplateModuleMutation,
    CreateTemplateModuleMutationVariables
  >(CreateTemplateModuleDocument, options)
}
export type CreateTemplateModuleMutationHookResult = ReturnType<
  typeof useCreateTemplateModuleMutation
>
export type CreateTemplateModuleMutationResult =
  Apollo.MutationResult<CreateTemplateModuleMutation>
export type CreateTemplateModuleMutationOptions = Apollo.BaseMutationOptions<
  CreateTemplateModuleMutation,
  CreateTemplateModuleMutationVariables
>
export const GetTemplateModulesDocument = gql`
  query getTemplateModules {
    templateModules {
      ...TEMPLATE_MODULE
    }
  }
  ${Template_ModuleFragmentDoc}
`

/**
 * __useGetTemplateModulesQuery__
 *
 * To run a query within a React component, call `useGetTemplateModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateModulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTemplateModulesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTemplateModulesQuery,
    GetTemplateModulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetTemplateModulesQuery,
    GetTemplateModulesQueryVariables
  >(GetTemplateModulesDocument, options)
}
export function useGetTemplateModulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTemplateModulesQuery,
    GetTemplateModulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTemplateModulesQuery,
    GetTemplateModulesQueryVariables
  >(GetTemplateModulesDocument, options)
}
export function useGetTemplateModulesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetTemplateModulesQuery,
    GetTemplateModulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetTemplateModulesQuery,
    GetTemplateModulesQueryVariables
  >(GetTemplateModulesDocument, options)
}
export type GetTemplateModulesQueryHookResult = ReturnType<
  typeof useGetTemplateModulesQuery
>
export type GetTemplateModulesLazyQueryHookResult = ReturnType<
  typeof useGetTemplateModulesLazyQuery
>
export type GetTemplateModulesSuspenseQueryHookResult = ReturnType<
  typeof useGetTemplateModulesSuspenseQuery
>
export type GetTemplateModulesQueryResult = Apollo.QueryResult<
  GetTemplateModulesQuery,
  GetTemplateModulesQueryVariables
>
export const TenderTypesDocument = gql`
  query tenderTypes {
    tenderTypes {
      id
      name
    }
  }
`

/**
 * __useTenderTypesQuery__
 *
 * To run a query within a React component, call `useTenderTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenderTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenderTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenderTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TenderTypesQuery,
    TenderTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TenderTypesQuery, TenderTypesQueryVariables>(
    TenderTypesDocument,
    options
  )
}
export function useTenderTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TenderTypesQuery,
    TenderTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TenderTypesQuery, TenderTypesQueryVariables>(
    TenderTypesDocument,
    options
  )
}
export function useTenderTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TenderTypesQuery,
    TenderTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<TenderTypesQuery, TenderTypesQueryVariables>(
    TenderTypesDocument,
    options
  )
}
export type TenderTypesQueryHookResult = ReturnType<typeof useTenderTypesQuery>
export type TenderTypesLazyQueryHookResult = ReturnType<
  typeof useTenderTypesLazyQuery
>
export type TenderTypesSuspenseQueryHookResult = ReturnType<
  typeof useTenderTypesSuspenseQuery
>
export type TenderTypesQueryResult = Apollo.QueryResult<
  TenderTypesQuery,
  TenderTypesQueryVariables
>
export const UsersDocument = gql`
  query users($accountId: Long!) {
    users(accountId: $accountId) {
      id
      name
    }
  }
`

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables> &
    ({ variables: UsersQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options
  )
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options
  )
}
export function useUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UsersQuery, UsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options
  )
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>
export type UsersSuspenseQueryHookResult = ReturnType<
  typeof useUsersSuspenseQuery
>
export type UsersQueryResult = Apollo.QueryResult<
  UsersQuery,
  UsersQueryVariables
>
export const UpdateCustomerDocument = gql`
  mutation updateCustomer($input: CustomerSearchDtoInput!) {
    updateCustomer(input: $input)
  }
`
export type UpdateCustomerMutationFn = Apollo.MutationFunction<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
  >(UpdateCustomerDocument, options)
}
export type UpdateCustomerMutationHookResult = ReturnType<
  typeof useUpdateCustomerMutation
>
export type UpdateCustomerMutationResult =
  Apollo.MutationResult<UpdateCustomerMutation>
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>
export const GetMeDocument = gql`
  query getMe {
    me {
      id
      permissions
      roles
    }
  }
`

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options
  )
}
export function useGetMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options
  )
}
export function useGetMeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetMeQuery, GetMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options
  )
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>
export type GetMeSuspenseQueryHookResult = ReturnType<
  typeof useGetMeSuspenseQuery
>
export type GetMeQueryResult = Apollo.QueryResult<
  GetMeQuery,
  GetMeQueryVariables
>
export const PrintOrdersDocument = gql`
  query printOrders($input: GetPrintOrdersInput!) {
    printOrders(input: $input) {
      order {
        id
        referenceNumber
        orderTypeId
        remoteReferenceNumber
        orderDate
        attribute2s {
          id
          name
          value
        }
      }
      sourceLocation {
        accountingCustomerNumber
        name
      }
      customer {
        accountNumber
      }
      entries {
        id
        quantityOnOrder
        entryAttributes {
          id
          name
          value
        }
        sku
        item {
          name
          description
          attribute1s {
            id
            name
            value
          }
        }
      }
    }
  }
`

/**
 * __usePrintOrdersQuery__
 *
 * To run a query within a React component, call `usePrintOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrintOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrintOrdersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrintOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<
    PrintOrdersQuery,
    PrintOrdersQueryVariables
  > &
    (
      | { variables: PrintOrdersQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PrintOrdersQuery, PrintOrdersQueryVariables>(
    PrintOrdersDocument,
    options
  )
}
export function usePrintOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PrintOrdersQuery,
    PrintOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PrintOrdersQuery, PrintOrdersQueryVariables>(
    PrintOrdersDocument,
    options
  )
}
export function usePrintOrdersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PrintOrdersQuery,
    PrintOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PrintOrdersQuery, PrintOrdersQueryVariables>(
    PrintOrdersDocument,
    options
  )
}
export type PrintOrdersQueryHookResult = ReturnType<typeof usePrintOrdersQuery>
export type PrintOrdersLazyQueryHookResult = ReturnType<
  typeof usePrintOrdersLazyQuery
>
export type PrintOrdersSuspenseQueryHookResult = ReturnType<
  typeof usePrintOrdersSuspenseQuery
>
export type PrintOrdersQueryResult = Apollo.QueryResult<
  PrintOrdersQuery,
  PrintOrdersQueryVariables
>
export const WorkflowDeleteOrderStateDocument = gql`
  mutation workflowDeleteOrderState($orderId: UUID!) {
    workflowDeleteOrderState(orderId: $orderId)
  }
`
export type WorkflowDeleteOrderStateMutationFn = Apollo.MutationFunction<
  WorkflowDeleteOrderStateMutation,
  WorkflowDeleteOrderStateMutationVariables
>

/**
 * __useWorkflowDeleteOrderStateMutation__
 *
 * To run a mutation, you first call `useWorkflowDeleteOrderStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkflowDeleteOrderStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workflowDeleteOrderStateMutation, { data, loading, error }] = useWorkflowDeleteOrderStateMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useWorkflowDeleteOrderStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WorkflowDeleteOrderStateMutation,
    WorkflowDeleteOrderStateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    WorkflowDeleteOrderStateMutation,
    WorkflowDeleteOrderStateMutationVariables
  >(WorkflowDeleteOrderStateDocument, options)
}
export type WorkflowDeleteOrderStateMutationHookResult = ReturnType<
  typeof useWorkflowDeleteOrderStateMutation
>
export type WorkflowDeleteOrderStateMutationResult =
  Apollo.MutationResult<WorkflowDeleteOrderStateMutation>
export type WorkflowDeleteOrderStateMutationOptions =
  Apollo.BaseMutationOptions<
    WorkflowDeleteOrderStateMutation,
    WorkflowDeleteOrderStateMutationVariables
  >
export const TimezonesDocument = gql`
  query timezones {
    timezones {
      id
      name
    }
  }
`

/**
 * __useTimezonesQuery__
 *
 * To run a query within a React component, call `useTimezonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimezonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimezonesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimezonesQuery(
  baseOptions?: Apollo.QueryHookOptions<TimezonesQuery, TimezonesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TimezonesQuery, TimezonesQueryVariables>(
    TimezonesDocument,
    options
  )
}
export function useTimezonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TimezonesQuery,
    TimezonesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TimezonesQuery, TimezonesQueryVariables>(
    TimezonesDocument,
    options
  )
}
export function useTimezonesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TimezonesQuery,
    TimezonesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<TimezonesQuery, TimezonesQueryVariables>(
    TimezonesDocument,
    options
  )
}
export type TimezonesQueryHookResult = ReturnType<typeof useTimezonesQuery>
export type TimezonesLazyQueryHookResult = ReturnType<
  typeof useTimezonesLazyQuery
>
export type TimezonesSuspenseQueryHookResult = ReturnType<
  typeof useTimezonesSuspenseQuery
>
export type TimezonesQueryResult = Apollo.QueryResult<
  TimezonesQuery,
  TimezonesQueryVariables
>
export const GetGlobalCustomersDocument = gql`
  query getGlobalCustomers($accountid: Long!) {
    globalCustomers(accountId: $accountid) {
      id
      accountNumber
      company
      inactive
      bOAC
      agencyCode
    }
  }
`

/**
 * __useGetGlobalCustomersQuery__
 *
 * To run a query within a React component, call `useGetGlobalCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalCustomersQuery({
 *   variables: {
 *      accountid: // value for 'accountid'
 *   },
 * });
 */
export function useGetGlobalCustomersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGlobalCustomersQuery,
    GetGlobalCustomersQueryVariables
  > &
    (
      | { variables: GetGlobalCustomersQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetGlobalCustomersQuery,
    GetGlobalCustomersQueryVariables
  >(GetGlobalCustomersDocument, options)
}
export function useGetGlobalCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGlobalCustomersQuery,
    GetGlobalCustomersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetGlobalCustomersQuery,
    GetGlobalCustomersQueryVariables
  >(GetGlobalCustomersDocument, options)
}
export function useGetGlobalCustomersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetGlobalCustomersQuery,
    GetGlobalCustomersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetGlobalCustomersQuery,
    GetGlobalCustomersQueryVariables
  >(GetGlobalCustomersDocument, options)
}
export type GetGlobalCustomersQueryHookResult = ReturnType<
  typeof useGetGlobalCustomersQuery
>
export type GetGlobalCustomersLazyQueryHookResult = ReturnType<
  typeof useGetGlobalCustomersLazyQuery
>
export type GetGlobalCustomersSuspenseQueryHookResult = ReturnType<
  typeof useGetGlobalCustomersSuspenseQuery
>
export type GetGlobalCustomersQueryResult = Apollo.QueryResult<
  GetGlobalCustomersQuery,
  GetGlobalCustomersQueryVariables
>
export const GetChildCustomersDocument = gql`
  query getChildCustomers($accountid: Long!, $parentid: UUID!) {
    childCustomers(accountId: $accountid, parentId: $parentid) {
      id
      accountNumber
      firstName
      inactive
      jon
      fundCode
      telephone
      cCCustomer
      email
      altEmail
      altEmail2
      altEmail3
    }
  }
`

/**
 * __useGetChildCustomersQuery__
 *
 * To run a query within a React component, call `useGetChildCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChildCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChildCustomersQuery({
 *   variables: {
 *      accountid: // value for 'accountid'
 *      parentid: // value for 'parentid'
 *   },
 * });
 */
export function useGetChildCustomersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetChildCustomersQuery,
    GetChildCustomersQueryVariables
  > &
    (
      | { variables: GetChildCustomersQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetChildCustomersQuery,
    GetChildCustomersQueryVariables
  >(GetChildCustomersDocument, options)
}
export function useGetChildCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChildCustomersQuery,
    GetChildCustomersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetChildCustomersQuery,
    GetChildCustomersQueryVariables
  >(GetChildCustomersDocument, options)
}
export function useGetChildCustomersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetChildCustomersQuery,
    GetChildCustomersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetChildCustomersQuery,
    GetChildCustomersQueryVariables
  >(GetChildCustomersDocument, options)
}
export type GetChildCustomersQueryHookResult = ReturnType<
  typeof useGetChildCustomersQuery
>
export type GetChildCustomersLazyQueryHookResult = ReturnType<
  typeof useGetChildCustomersLazyQuery
>
export type GetChildCustomersSuspenseQueryHookResult = ReturnType<
  typeof useGetChildCustomersSuspenseQuery
>
export type GetChildCustomersQueryResult = Apollo.QueryResult<
  GetChildCustomersQuery,
  GetChildCustomersQueryVariables
>
export const GetLoggedInUsersPermissionsDocument = gql`
  query getLoggedInUsersPermissions {
    me {
      id
      permissions
    }
  }
`

/**
 * __useGetLoggedInUsersPermissionsQuery__
 *
 * To run a query within a React component, call `useGetLoggedInUsersPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoggedInUsersPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoggedInUsersPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoggedInUsersPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLoggedInUsersPermissionsQuery,
    GetLoggedInUsersPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetLoggedInUsersPermissionsQuery,
    GetLoggedInUsersPermissionsQueryVariables
  >(GetLoggedInUsersPermissionsDocument, options)
}
export function useGetLoggedInUsersPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoggedInUsersPermissionsQuery,
    GetLoggedInUsersPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetLoggedInUsersPermissionsQuery,
    GetLoggedInUsersPermissionsQueryVariables
  >(GetLoggedInUsersPermissionsDocument, options)
}
export function useGetLoggedInUsersPermissionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetLoggedInUsersPermissionsQuery,
    GetLoggedInUsersPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetLoggedInUsersPermissionsQuery,
    GetLoggedInUsersPermissionsQueryVariables
  >(GetLoggedInUsersPermissionsDocument, options)
}
export type GetLoggedInUsersPermissionsQueryHookResult = ReturnType<
  typeof useGetLoggedInUsersPermissionsQuery
>
export type GetLoggedInUsersPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetLoggedInUsersPermissionsLazyQuery
>
export type GetLoggedInUsersPermissionsSuspenseQueryHookResult = ReturnType<
  typeof useGetLoggedInUsersPermissionsSuspenseQuery
>
export type GetLoggedInUsersPermissionsQueryResult = Apollo.QueryResult<
  GetLoggedInUsersPermissionsQuery,
  GetLoggedInUsersPermissionsQueryVariables
>
export const GetLoggedInUsersAccountDocument = gql`
  query getLoggedInUsersAccount {
    me {
      id
      accountId
    }
  }
`

/**
 * __useGetLoggedInUsersAccountQuery__
 *
 * To run a query within a React component, call `useGetLoggedInUsersAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoggedInUsersAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoggedInUsersAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoggedInUsersAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLoggedInUsersAccountQuery,
    GetLoggedInUsersAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetLoggedInUsersAccountQuery,
    GetLoggedInUsersAccountQueryVariables
  >(GetLoggedInUsersAccountDocument, options)
}
export function useGetLoggedInUsersAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoggedInUsersAccountQuery,
    GetLoggedInUsersAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetLoggedInUsersAccountQuery,
    GetLoggedInUsersAccountQueryVariables
  >(GetLoggedInUsersAccountDocument, options)
}
export function useGetLoggedInUsersAccountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetLoggedInUsersAccountQuery,
    GetLoggedInUsersAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetLoggedInUsersAccountQuery,
    GetLoggedInUsersAccountQueryVariables
  >(GetLoggedInUsersAccountDocument, options)
}
export type GetLoggedInUsersAccountQueryHookResult = ReturnType<
  typeof useGetLoggedInUsersAccountQuery
>
export type GetLoggedInUsersAccountLazyQueryHookResult = ReturnType<
  typeof useGetLoggedInUsersAccountLazyQuery
>
export type GetLoggedInUsersAccountSuspenseQueryHookResult = ReturnType<
  typeof useGetLoggedInUsersAccountSuspenseQuery
>
export type GetLoggedInUsersAccountQueryResult = Apollo.QueryResult<
  GetLoggedInUsersAccountQuery,
  GetLoggedInUsersAccountQueryVariables
>
export const ReportsDocument = gql`
  query reports {
    reports {
      id
      name
    }
  }
`

/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<ReportsQuery, ReportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReportsQuery, ReportsQueryVariables>(
    ReportsDocument,
    options
  )
}
export function useReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReportsQuery, ReportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReportsQuery, ReportsQueryVariables>(
    ReportsDocument,
    options
  )
}
export function useReportsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ReportsQuery,
    ReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ReportsQuery, ReportsQueryVariables>(
    ReportsDocument,
    options
  )
}
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>
export type ReportsSuspenseQueryHookResult = ReturnType<
  typeof useReportsSuspenseQuery
>
export type ReportsQueryResult = Apollo.QueryResult<
  ReportsQuery,
  ReportsQueryVariables
>
export const UsersByDodaacBeaBesaDocument = gql`
  query usersByDodaacBeaBesa($input: UsersByDodaacBeaBesaInput!) {
    usersByDodaacBeaBesa(input: $input) {
      id
      name
      dodaacBeaBesas {
        id
        value
      }
      roles {
        id
        role
      }
    }
  }
`

/**
 * __useUsersByDodaacBeaBesaQuery__
 *
 * To run a query within a React component, call `useUsersByDodaacBeaBesaQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersByDodaacBeaBesaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersByDodaacBeaBesaQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsersByDodaacBeaBesaQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsersByDodaacBeaBesaQuery,
    UsersByDodaacBeaBesaQueryVariables
  > &
    (
      | { variables: UsersByDodaacBeaBesaQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UsersByDodaacBeaBesaQuery,
    UsersByDodaacBeaBesaQueryVariables
  >(UsersByDodaacBeaBesaDocument, options)
}
export function useUsersByDodaacBeaBesaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersByDodaacBeaBesaQuery,
    UsersByDodaacBeaBesaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UsersByDodaacBeaBesaQuery,
    UsersByDodaacBeaBesaQueryVariables
  >(UsersByDodaacBeaBesaDocument, options)
}
export function useUsersByDodaacBeaBesaSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UsersByDodaacBeaBesaQuery,
    UsersByDodaacBeaBesaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    UsersByDodaacBeaBesaQuery,
    UsersByDodaacBeaBesaQueryVariables
  >(UsersByDodaacBeaBesaDocument, options)
}
export type UsersByDodaacBeaBesaQueryHookResult = ReturnType<
  typeof useUsersByDodaacBeaBesaQuery
>
export type UsersByDodaacBeaBesaLazyQueryHookResult = ReturnType<
  typeof useUsersByDodaacBeaBesaLazyQuery
>
export type UsersByDodaacBeaBesaSuspenseQueryHookResult = ReturnType<
  typeof useUsersByDodaacBeaBesaSuspenseQuery
>
export type UsersByDodaacBeaBesaQueryResult = Apollo.QueryResult<
  UsersByDodaacBeaBesaQuery,
  UsersByDodaacBeaBesaQueryVariables
>
export const UsersAddToDodaacBeaBesaDocument = gql`
  mutation usersAddToDodaacBeaBesa($input: UsersAddToDodaacBeaBesaInput!) {
    usersAddToDodaacBeaBesa(input: $input)
  }
`
export type UsersAddToDodaacBeaBesaMutationFn = Apollo.MutationFunction<
  UsersAddToDodaacBeaBesaMutation,
  UsersAddToDodaacBeaBesaMutationVariables
>

/**
 * __useUsersAddToDodaacBeaBesaMutation__
 *
 * To run a mutation, you first call `useUsersAddToDodaacBeaBesaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUsersAddToDodaacBeaBesaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [usersAddToDodaacBeaBesaMutation, { data, loading, error }] = useUsersAddToDodaacBeaBesaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsersAddToDodaacBeaBesaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UsersAddToDodaacBeaBesaMutation,
    UsersAddToDodaacBeaBesaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UsersAddToDodaacBeaBesaMutation,
    UsersAddToDodaacBeaBesaMutationVariables
  >(UsersAddToDodaacBeaBesaDocument, options)
}
export type UsersAddToDodaacBeaBesaMutationHookResult = ReturnType<
  typeof useUsersAddToDodaacBeaBesaMutation
>
export type UsersAddToDodaacBeaBesaMutationResult =
  Apollo.MutationResult<UsersAddToDodaacBeaBesaMutation>
export type UsersAddToDodaacBeaBesaMutationOptions = Apollo.BaseMutationOptions<
  UsersAddToDodaacBeaBesaMutation,
  UsersAddToDodaacBeaBesaMutationVariables
>
export const UsersRemoveFromDodaacBeaBesaDocument = gql`
  mutation usersRemoveFromDodaacBeaBesa(
    $input: UsersRemoveFromDodaacBeaBesaInput!
  ) {
    usersRemoveFromDodaacBeaBesa(input: $input)
  }
`
export type UsersRemoveFromDodaacBeaBesaMutationFn = Apollo.MutationFunction<
  UsersRemoveFromDodaacBeaBesaMutation,
  UsersRemoveFromDodaacBeaBesaMutationVariables
>

/**
 * __useUsersRemoveFromDodaacBeaBesaMutation__
 *
 * To run a mutation, you first call `useUsersRemoveFromDodaacBeaBesaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUsersRemoveFromDodaacBeaBesaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [usersRemoveFromDodaacBeaBesaMutation, { data, loading, error }] = useUsersRemoveFromDodaacBeaBesaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsersRemoveFromDodaacBeaBesaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UsersRemoveFromDodaacBeaBesaMutation,
    UsersRemoveFromDodaacBeaBesaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UsersRemoveFromDodaacBeaBesaMutation,
    UsersRemoveFromDodaacBeaBesaMutationVariables
  >(UsersRemoveFromDodaacBeaBesaDocument, options)
}
export type UsersRemoveFromDodaacBeaBesaMutationHookResult = ReturnType<
  typeof useUsersRemoveFromDodaacBeaBesaMutation
>
export type UsersRemoveFromDodaacBeaBesaMutationResult =
  Apollo.MutationResult<UsersRemoveFromDodaacBeaBesaMutation>
export type UsersRemoveFromDodaacBeaBesaMutationOptions =
  Apollo.BaseMutationOptions<
    UsersRemoveFromDodaacBeaBesaMutation,
    UsersRemoveFromDodaacBeaBesaMutationVariables
  >
export const GetWorkflowSupportDocument = gql`
  query getWorkflowSupport($accountId: Long!) {
    workflowSupport(accountId: $accountId) {
      pluginVersion
      workflowConfiguration {
        id
        enabled
        serviceUrl
      }
      ordersTotal
      orders {
        id
        orderStatus
        referenceNumber
        created
        dodaacBeaBesa
        accessCode
        approvalStepsCompletedCount
        approvelStepsTotalCount
        approvalPercentComplete
        workflowStatus {
          id
          status
          errorCode
          rawError
        }
      }
    }
  }
`

/**
 * __useGetWorkflowSupportQuery__
 *
 * To run a query within a React component, call `useGetWorkflowSupportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkflowSupportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkflowSupportQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetWorkflowSupportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkflowSupportQuery,
    GetWorkflowSupportQueryVariables
  > &
    (
      | { variables: GetWorkflowSupportQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetWorkflowSupportQuery,
    GetWorkflowSupportQueryVariables
  >(GetWorkflowSupportDocument, options)
}
export function useGetWorkflowSupportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkflowSupportQuery,
    GetWorkflowSupportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetWorkflowSupportQuery,
    GetWorkflowSupportQueryVariables
  >(GetWorkflowSupportDocument, options)
}
export function useGetWorkflowSupportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetWorkflowSupportQuery,
    GetWorkflowSupportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetWorkflowSupportQuery,
    GetWorkflowSupportQueryVariables
  >(GetWorkflowSupportDocument, options)
}
export type GetWorkflowSupportQueryHookResult = ReturnType<
  typeof useGetWorkflowSupportQuery
>
export type GetWorkflowSupportLazyQueryHookResult = ReturnType<
  typeof useGetWorkflowSupportLazyQuery
>
export type GetWorkflowSupportSuspenseQueryHookResult = ReturnType<
  typeof useGetWorkflowSupportSuspenseQuery
>
export type GetWorkflowSupportQueryResult = Apollo.QueryResult<
  GetWorkflowSupportQuery,
  GetWorkflowSupportQueryVariables
>
export const WorkflowInvokeDocument = gql`
  mutation workflowInvoke($orderId: UUID!) {
    workflowInvoke(input: { orderId: $orderId }) {
      success
    }
  }
`
export type WorkflowInvokeMutationFn = Apollo.MutationFunction<
  WorkflowInvokeMutation,
  WorkflowInvokeMutationVariables
>

/**
 * __useWorkflowInvokeMutation__
 *
 * To run a mutation, you first call `useWorkflowInvokeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkflowInvokeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workflowInvokeMutation, { data, loading, error }] = useWorkflowInvokeMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useWorkflowInvokeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WorkflowInvokeMutation,
    WorkflowInvokeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    WorkflowInvokeMutation,
    WorkflowInvokeMutationVariables
  >(WorkflowInvokeDocument, options)
}
export type WorkflowInvokeMutationHookResult = ReturnType<
  typeof useWorkflowInvokeMutation
>
export type WorkflowInvokeMutationResult =
  Apollo.MutationResult<WorkflowInvokeMutation>
export type WorkflowInvokeMutationOptions = Apollo.BaseMutationOptions<
  WorkflowInvokeMutation,
  WorkflowInvokeMutationVariables
>
export const GetWorkflowSupportOrderDocument = gql`
  query getWorkflowSupportOrder($orderId: UUID!) {
    workflowSupportOrder(orderId: $orderId) {
      runCount
      referenceNumber
      histories {
        id
        message
        date
      }
      events {
        id
        name
        data
        date
      }
    }
  }
`

/**
 * __useGetWorkflowSupportOrderQuery__
 *
 * To run a query within a React component, call `useGetWorkflowSupportOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkflowSupportOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkflowSupportOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetWorkflowSupportOrderQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkflowSupportOrderQuery,
    GetWorkflowSupportOrderQueryVariables
  > &
    (
      | { variables: GetWorkflowSupportOrderQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetWorkflowSupportOrderQuery,
    GetWorkflowSupportOrderQueryVariables
  >(GetWorkflowSupportOrderDocument, options)
}
export function useGetWorkflowSupportOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkflowSupportOrderQuery,
    GetWorkflowSupportOrderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetWorkflowSupportOrderQuery,
    GetWorkflowSupportOrderQueryVariables
  >(GetWorkflowSupportOrderDocument, options)
}
export function useGetWorkflowSupportOrderSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetWorkflowSupportOrderQuery,
    GetWorkflowSupportOrderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetWorkflowSupportOrderQuery,
    GetWorkflowSupportOrderQueryVariables
  >(GetWorkflowSupportOrderDocument, options)
}
export type GetWorkflowSupportOrderQueryHookResult = ReturnType<
  typeof useGetWorkflowSupportOrderQuery
>
export type GetWorkflowSupportOrderLazyQueryHookResult = ReturnType<
  typeof useGetWorkflowSupportOrderLazyQuery
>
export type GetWorkflowSupportOrderSuspenseQueryHookResult = ReturnType<
  typeof useGetWorkflowSupportOrderSuspenseQuery
>
export type GetWorkflowSupportOrderQueryResult = Apollo.QueryResult<
  GetWorkflowSupportOrderQuery,
  GetWorkflowSupportOrderQueryVariables
>
export const GetSupportOrderWorkflowGridDocument = gql`
  query getSupportOrderWorkflowGrid(
    $where: OrderFilterInput
    $order: [OrderSortInput!]
    $first: Int
  ) {
    testies(where: $where, order: $order, first: $first) {
      __typename
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        dodaacBeaBesa
        accessCode
        workflowStatus {
          id
          status
          errorCode
          rawError
          stepInformation {
            completeCount
            stepTotal
            percentComplete
          }
        }
        __typename
        cursor
        node {
          __typename
          id
          accountId
          orderTypeId
          status
          dateCreated
          referenceNumber
          accountId
          orderDate
          attribute2s {
            id
            name
            value
          }
          events {
            id
            name
            data
          }
        }
      }
    }
  }
`

/**
 * __useGetSupportOrderWorkflowGridQuery__
 *
 * To run a query within a React component, call `useGetSupportOrderWorkflowGridQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportOrderWorkflowGridQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportOrderWorkflowGridQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetSupportOrderWorkflowGridQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSupportOrderWorkflowGridQuery,
    GetSupportOrderWorkflowGridQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSupportOrderWorkflowGridQuery,
    GetSupportOrderWorkflowGridQueryVariables
  >(GetSupportOrderWorkflowGridDocument, options)
}
export function useGetSupportOrderWorkflowGridLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSupportOrderWorkflowGridQuery,
    GetSupportOrderWorkflowGridQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSupportOrderWorkflowGridQuery,
    GetSupportOrderWorkflowGridQueryVariables
  >(GetSupportOrderWorkflowGridDocument, options)
}
export function useGetSupportOrderWorkflowGridSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSupportOrderWorkflowGridQuery,
    GetSupportOrderWorkflowGridQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetSupportOrderWorkflowGridQuery,
    GetSupportOrderWorkflowGridQueryVariables
  >(GetSupportOrderWorkflowGridDocument, options)
}
export type GetSupportOrderWorkflowGridQueryHookResult = ReturnType<
  typeof useGetSupportOrderWorkflowGridQuery
>
export type GetSupportOrderWorkflowGridLazyQueryHookResult = ReturnType<
  typeof useGetSupportOrderWorkflowGridLazyQuery
>
export type GetSupportOrderWorkflowGridSuspenseQueryHookResult = ReturnType<
  typeof useGetSupportOrderWorkflowGridSuspenseQuery
>
export type GetSupportOrderWorkflowGridQueryResult = Apollo.QueryResult<
  GetSupportOrderWorkflowGridQuery,
  GetSupportOrderWorkflowGridQueryVariables
>

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ModuleConfiguration: [
      'InventoryModuleConfiguration',
      'LocationsModuleConfiguration',
      'NoModuleConfiguration',
      'PosModuleConfiguration',
      'WorkflowModuleConfiguration',
    ],
  },
}
export default result
