import { Grid, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => {
  return {
    footer: {
      backgroundColor: '#3b3e4b',
      color: 'white',
      position: 'fixed',
      left: 0,
      bottom: 0,
      width: '100%',
      '@media print': {
        display: 'none',
      },
    },
    content: {
      display: 'flex',
      alignItems: 'center',
    },
    link: {
      marginLeft: 10,
      paddingTop: 8,
      color: 'white',
    },
    image: {
      paddingTop: 8,
    },
    version: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: 10,
    },
    versionText: {
      marginLeft: 'auto',
    },
    accessibilityLink: {
      marginLeft: 10,
      color: 'white',
    },
  }
})
export default function Footer() {
  const { classes } = useStyles()
  const version = (process.env.NEXT_PUBLIC_APP_VERSION || '').replace(
    'refs/tags/',
    ''
  )
  const sha = (process.env.NEXT_PUBLIC_APP_SHA || '').slice(0, 6)

  return (
    <footer className={classes.footer}>
      <Grid container>
        <Grid item className={classes.content} xs={6}>
          <a
            target="_blank"
            href="https://www.gsa.gov/website-information/accessibility-statement"
            rel="noreferrer"
            className={classes.accessibilityLink}
          >
            Accessibility Statement
          </a>
        </Grid>
        <Grid item className={classes.content} xs={3}>
          <Typography variant="body2">Powered by </Typography>
          <a
            target="_blank"
            href="http://www.nchannel.com"
            rel="noreferrer"
            className={classes.link}
          >
            <img alt="nChannel Logo" src="/logo.png" height={26} width={100} />
          </a>
        </Grid>
        <Grid item xs={3} className={classes.version}>
          <Typography variant="body2" className={classes.versionText}>
            {version} {sha && `(${sha})`}
          </Typography>
        </Grid>
      </Grid>
    </footer>
  )
}
